.page-component__bg_image_box
	.page-component__bg_image_box
	.page-component__bg_overlay_box {
	position: relative;
	color: black;
}
.page-component__bg_image_box {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
}
.page-component__bg_image_box--has-image .page-component__bg_overlay_box {
	opacity: 0.8;
}
.page-component__bg_image_box--has-image .header-04__logos .logos__img {
	opacity: 0.6;
}
.page-component__wrapper {
	position: relative;
}
.text--02 h3 {
	margin-bottom: 0.5em;
	margin-top: 0.5em;
	font-weight: 700;
	line-height: 1.3;
	color: #303030;
	font-size: 1.2rem;
}
.content_box h2,
.content_box h3,
.content_box h4,
.content_box h5,
.content_box h6 {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	padding-bottom: 0;
}
.content_box p,
.content_box ul,
.content_box ol,
.content_box img:not(.checkbox__icon),
.content_box table,
.content_box iframe,
.content_box figure,
.content_box blockquote,
.content_box code {
	margin-bottom: 1em;
	margin-top: 1em;
	line-height: 1.6;
	font-size: 16px;
}
.content_box p:first-child {
	margin-top: 0;
}

.content_box p:last-child {
	margin-bottom: 0;
}
.footer-02__text.content_box p {
	margin: 0;
}
.footer-02__text.content_box p:first-child {
	display: inline;
}
.content_box h2 {
	font-size: 1.4em;
}

.content_box h3 {
	font-size: 1.2em;
}
.popup-component .page-component__wrapper {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}
.container--mid {
	max-width: 900px;
}
.text--02 {
	overflow: hidden;
	padding: 40px 0;
	color: #303030;
}

.text--02__box {
	position: relative;
}

.text--02__content_box {
	padding: 60px 90px 35px;
	box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2),
		0 5px 15px 0 rgba(37, 44, 97, 0.15);
	background-color: white;
}

.text--02__content_box--top {
	position: relative;
	z-index: 10;
}

.text--02__content_box--bottom {
	position: absolute;
	z-index: 0;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	margin: auto;
	transform: rotate(-3deg);
	box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2),
		0 5px 15px 0 rgba(37, 44, 97, 0.15);
	background-color: white;
}

@media (max-width: 700px) {
	.text--02__content_box {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.text--02__img {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	margin: auto;
	transform: translateY(-50%);
	height: 60px;
	text-align: center;
}

.text--02__img img {
	max-height: 100%;
}

.text--02__link_box {
	margin-top: 20px;
}
.privacy-link:hover {
	color: white !important;
}
