.content ul {
	list-style: circle !important;
	list-style-position: outside !important;
	padding: 0 20px;
}
.content ol {
	list-style: decimal !important;
	list-style-position: outside !important;
	padding: 0 20px;
}
