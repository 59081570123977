.popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
}

.popup-content {
	/* background-color: linear-gradient(toright, black, #fff 100%, black 0); */
	background-image: url("../../Images/contact-us-bg.jpg"),
		linear-gradient(to right, black, #fff 100%, black 0) !important;
	/* border-radius: 5px; */
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 100% 0% !important;
	width: 100%;
	height: 100%;
	text-align: center;
	position: relative;
	overflow: auto; /* Add overflow property for scroll if needed */
}
.popup-content .card {
	background-color: rgba(0, 0, 0, 0.7) !important;
}
.popup-content .card .main-title :where(h2, p) {
	color: #fff;
}
.close-btn {
	position: absolute;
	top: 15px;
	left: 15px;
	font-size: 18px;
	cursor: pointer;
	color: #000;
	border: none;
	background-color: transparent;
}

/* .popup-content select {
	height: calc(2em + 0.75rem + 2px) !important;
	padding: 0.375rem 0.75rem !important;
	-webkit-appearance: none;
	-moz-appearance: none;
	line-height: 2;
} */
/* .popup-content select.round {
	background-image: linear-gradient(45deg, transparent 50%, #d91a60 50%),
		linear-gradient(135deg, #d91a60 50%, transparent 50%),
		radial-gradient(white 70%, transparent 72%);
	background-position: calc(100% - 20px) calc(1em + 2px),
		calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
	background-repeat: no-repeat;
}

.popup-content select.round:focus {
	background-image: linear-gradient(45deg, white 50%, transparent 50%),
		linear-gradient(135deg, transparent 50%, white 50%),
		radial-gradient(#d91a60 70%, transparent 72%);
	background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
		calc(100% - 0.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
	background-repeat: no-repeat;
	border-color: green;
	outline: 0;
}
option {
	min-height: 1.3rem;
	display: block;
	padding: 20px;
} */
.contact-img {
	width: 100%;
	height: 100%;
	aspect-ratio: 3/2;
}
.image-container {
	position: fixed !important;
	right: 0;
	height: 100vh;
}
.p-4rem {
	padding: 4rem 4rem 4rem 0;
}
.popup-content input {
	padding: 1.1rem 0.75rem !important;
	background-color: transparent;
	color: white !important;
}
.popup-content .form-control {
	background-color: transparent;
	color: white !important;
	margin-bottom: 10px !important;
}
.popup-content input::placeholder {
	color: white !important;
}
.popup-content .select-items div,
.popup-content textarea::placeholder,
.popup-content textarea,
.select-selected {
	color: white !important;
	background-color: transparent !important;
}
.popup-content .submit-btn {
	background: linear-gradient(102.57deg, #d0121e, #f84d9f 100%, #f84d9f 0);
	padding: 0.8rem 4rem;
	font-size: 1rem;
	font-weight: 500;
	color: white;
}
.popup-content .submit-btn:hover {
	color: white;
}
.popup-content .check-box {
	display: flex;
	align-items: flex-start;
	gap: 10px;
}
.popup-content .check-box label::before {
	background-color: #50cdbe;
	border-radius: 3px;
}
.popup-content .check-box label {
	font-size: 0.7rem;
	line-height: 1.3;
	color: #fff;
	text-align: left;
}
.popup-content .check-box input {
	width: 1rem;
	height: 1rem;
	border-radius: 4px;
	background-color: unset;
	margin-top: 4px;
	cursor: pointer;
}
.popup-content .check-box input:checked {
	background-color: #d91a60 !important;
}
@media screen and (max-width: 967px) {
	.image-container {
		display: none;
	}
	.close-btn {
		left: 10px;
		z-index: 9999;
	}
	.select-items div,
	.select-selected {
		height: auto !important;
	}
	.p-4rem {
		padding: 4rem 2rem;
	}
	.popup-content {
		background-position: 65% 0% !important;
	}
	.buttons {
		flex-direction: column-reverse;
		gap: 20px;
	}
}

.custom-select-pop {
	position: relative;
	font-family: Arial;
	margin-bottom: 20px;
}

.custom-select-pop select {
	display: none; /*hide original SELECT element:*/
}

/* .select-selected {
	background-color: DodgerBlue;
} */

/*style the arrow inside the select element:*/
.select-selected:after {
	position: absolute;
	content: "";
	top: 17px;
	right: 10px;
	width: 0;
	height: 0;
	border: 6px solid transparent;
	border-color: #d91a60 transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
	border-color: transparent transparent #d91a60 transparent;
	top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
	padding: 0.7rem 0.75em;
	/* border: 1px solid transparent;
	border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent; */
	cursor: pointer;
	user-select: none;
	display: block;
	width: 100%;
	height: calc(1.2em + 1.2rem + 2px);
	/* padding: 1.3rem 0.75rem; */
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	text-align: left;
}
.select-selected {
	text-wrap: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.select-items div {
	border-radius: 0;
	height: auto;
}

/*style items (options):*/
.select-items {
	position: absolute;
	/* background-color: DodgerBlue; */
	top: 100%;
	left: 0;
	right: 0;
	z-index: 99;
	max-height: 230px;
	overflow: auto;
	border: 1px solid lightgray;
	background-color: black;
}

/*hide the items when the select box is closed:*/
.select-hide {
	display: none;
}

.select-items div:hover,
.same-as-selected {
	background-color: #d91a60;
	color: white;
}
.same-as-selected {
	background-color: #d91a60 !important;
	color: white !important;
}
.Toastify__toast-container {
	width: auto !important;
}
.Toastify__toast-container button {
	margin-left: 10px;
	margin-right: 10px;
	align-self: center;
}
.progress {
	border-radius: 50px !important;
}
.progress-bar {
	background-color: #d91a60 !important;
}
.hide-section2 {
	transition: all 0.8s ease-in-out;
}
.buttons {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
}
.buttons button {
	padding: 0.8rem 4rem;
	font-size: 1rem;
	font-weight: 500;
	color: white;
}
