figcaption {
	text-align: center !important;
	color: #6b6b6b;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	margin: 10px auto;
}
figure {
	text-align: center !important;
}
#resources-home .main-font {
	font-family: "Raleway", sans-serif;
}
#resources-home h2,
#resources-home h5,
#about-home h2,
#about-home h5,
.blog.page_header :where(h2, h5) {
	line-height: 1.2;
	font-weight: 400;
}
.team-classic .image img {
	height: auto;
	object-fit: cover;
}
.team-classic .item {
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
		rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
#resources-home {
	background-image: url("../../Images/resources-bg-1.jpg");
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	color: #ffffff;
	background-position: center center !important;
}
.date {
	font-family: "open sans", sans-serif;
}
#about-home {
	background-image: url("../../Images/resources.jpg");
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	color: #ffffff;
	background-position: center center;
}

#resources-home h2,
#about-home h2 .blog.page_header h2 {
	font-size: 74px;
}

#tabs .nav-link.active {
	color: red;
}
.react-tabs__tab-list {
	border-bottom: none !important;
	display: flex;
	justify-content: center;
	margin: 0 0 20px !important;
	gap: 20px;
}
.react-tabs__tab--selected {
	border: none !important;
	color: #d91a60 !important;
}
.react-tabs__tab {
	color: black;
	font-size: 1.65rem;
	font-weight: 600;
}
.blog-box {
	box-shadow: none !important;
}
.blogs-grid .blog .article-type {
	position: absolute;
	top: 2rem;
	left: 1.5rem;
	padding: 5px 10px;
	background-color: #d91a60;
	font-size: 1.12rem;
	font-weight: 400;
	color: #ffffff;
}
.blogs-grid {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	/* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
	gap: 2rem;
	justify-items: center;
}
.blogs-grid > .blog {
	max-width: 360px;
}
.resources.blogs-grid > .blog {
	margin-top: 50px;
}
.news-item .para p {
	font-size: 1rem;
}
.news-item:hover .article-type {
	color: #ffffff !important;
}
.news-item .learn-more-btn {
	color: #d91a60;
	bottom: 15px;
}
.news-item .news-text-box {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: 190px;
}
.news-item .author {
	display: flex;
	align-items: center;
	gap: 10px;
}
.news-item .author span {
	transition: all 0.01s ease-in;
}
.news-item .author .desc {
	font-weight: 300;
}
.news-item .author img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}
.news-item:hover {
	background-color: rgba(0, 0, 0, 0.7);
	color: #ffffff;
}
.news-item:hover .learn-more-btn,
.news-item:hover .date,
.news-item:hover .para p {
	color: #ffffff;
}
.news-item:hover img {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}
.service-case-cards {
	display: grid;
	gap: 3rem;
	grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
}
.resources.service-case-cards {
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
/* roadmap */
/* styles.css */

.timeline {
	position: relative;
	padding: 4px 0 0 0;
	margin-top: 22px;
	list-style: none;
}

.timeline > li:nth-child(even) {
	position: relative;
	margin-bottom: 50px;
	height: 330px;
	right: -100px;
}

.timeline > li:nth-child(odd) {
	position: relative;
	margin-bottom: 50px;
	height: 330px;
	left: -100px;
}

.timeline > li:before,
.timeline > li:after {
	content: " ";
	display: table;
}

.timeline > li:after {
	clear: both;
	min-height: 170px;
}

.timeline > li .timeline-panel {
	position: relative;
	float: left;
	width: 41%;
	padding: 0 20px 20px 30px;
	text-align: right;
}

.timeline > li .timeline-panel:before {
	right: auto;
	left: -15px;
	border-right-width: 15px;
	border-left-width: 0;
}

.timeline > li .timeline-panel:after {
	right: auto;
	left: -14px;
	border-right-width: 14px;
	border-left-width: 0;
}

.timeline > li .timeline-image {
	z-index: 1;
	position: absolute;
	left: 50%;
	border: 7px solid #d91a60;
	border-radius: 100%;
	background-color: #d91a60;
	box-shadow: 0 0 5px #d91a60;
	width: 200px;
	height: 200px;
	margin-left: -100px;
}

.timeline > li .timeline-image h4 {
	margin-top: 12px;
	font-size: 10px;
	line-height: 14px;
}

.timeline > li.timeline-inverted > .timeline-panel {
	float: right;
	padding: 0 30px 20px 20px;
	text-align: left;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
	right: auto;
	left: -15px;
	border-right-width: 15px;
	border-left-width: 0;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
	right: auto;
	left: -14px;
	border-right-width: 14px;
	border-left-width: 0;
}

.timeline > li:last-child {
	margin-bottom: 0;
}

.timeline .timeline-heading h4 {
	margin-top: 22px;
	margin-bottom: 4px;
	padding: 0;
	color: #b3b3b3;
}

.timeline .timeline-heading h4.subheading {
	margin: 0;
	padding: 0;
	text-transform: none;
	font-size: 18px;
	color: #333333;
}

.timeline .timeline-body > p,
.timeline .timeline-body > ul {
	margin-bottom: 0;
	color: #808080;
}
/*Style for even div.line*/
.timeline > li:nth-child(odd) .line:before {
	content: "";
	position: absolute;
	top: 60px;
	bottom: 0;
	left: 690px;
	width: 4px;
	height: 340px;
	background-color: #d91a60;
	-ms-transform: rotate(-44deg); /* IE 9 */
	-webkit-transform: rotate(-44deg); /* Safari */
	transform: rotate(-44deg);
	box-shadow: 0 0 5px #d91a60;
}
/*Style for odd div.line*/
.timeline > li:nth-child(even) .line:before {
	content: "";
	position: absolute;
	top: 60px;
	bottom: 0;
	left: 450px;
	width: 4px;
	height: 340px;
	background-color: #d91a60;
	-ms-transform: rotate(44deg); /* IE 9 */
	-webkit-transform: rotate(44deg); /* Safari */
	transform: rotate(44deg);
	box-shadow: 0 0 5px #d91a60;
}
/* Medium Devices, .visible-md-* */
@media (min-width: 992px) and (max-width: 1199px) {
	.timeline > li:nth-child(even) {
		margin-bottom: 0px;
		min-height: 0px;
		right: 0px;
	}
	.timeline > li:nth-child(odd) {
		margin-bottom: 0px;
		min-height: 0px;
		left: 0px;
	}
	.timeline > li:nth-child(even) .timeline-image {
		left: 0;
		margin-left: 0px;
	}
	.timeline > li:nth-child(odd) .timeline-image {
		left: 690px;
		margin-left: 0px;
	}
	.timeline > li:nth-child(even) .timeline-panel {
		width: 76%;
		padding: 0 0 20px 0px;
		text-align: left;
	}
	.timeline > li:nth-child(odd) .timeline-panel {
		width: 70%;
		padding: 0 0 20px 0px;
		text-align: right;
	}
	.timeline > li .line {
		display: none;
	}
	.blogs-grid > * {
		max-width: 400px;
	}
}
/* Small Devices, Tablets */
@media (min-width: 768px) and (max-width: 991px) {
	.blogs-grid > * {
		max-width: 100%;
	}
	.timeline > li:nth-child(even) {
		margin-bottom: 0px;
		min-height: 0px;
		right: 0px;
	}
	.timeline > li:nth-child(odd) {
		margin-bottom: 0px;
		min-height: 0px;
		left: 0px;
	}
	.timeline > li:nth-child(even) .timeline-image {
		left: 0;
		margin-left: 0px;
	}
	.timeline > li:nth-child(odd) .timeline-image {
		left: 520px;
		margin-left: 0px;
	}
	.timeline > li:nth-child(even) .timeline-panel {
		width: 70%;
		padding: 0 0 20px 0px;
		text-align: left;
	}
	.timeline > li:nth-child(odd) .timeline-panel {
		width: 70%;
		padding: 0 0 20px 0px;
		text-align: right;
	}
	.timeline > li .line {
		display: none;
	}
}
/* Custom, iPhone Retina */
@media only screen and (max-width: 767px) {
	.blogs-grid {
		gap: 2rem;
	}
	.blogs-grid > * {
		max-width: 100%;
	}
	.timeline > li:nth-child(even) {
		margin-bottom: 0px;
		min-height: 0px;
		right: 0px;
	}
	.timeline > li:nth-child(odd) {
		margin-bottom: 0px;
		min-height: 0px;
		left: 0px;
	}
	.timeline > li .timeline-image {
		position: static;
		width: 150px;
		height: 150px;
		margin-bottom: 0px;
	}
	.timeline > li:nth-child(even) .timeline-image {
		left: 0;
		margin-left: 0;
	}
	.timeline > li:nth-child(odd) .timeline-image {
		float: right;
		left: 0px;
		margin-left: 0;
	}
	.timeline > li:nth-child(even) .timeline-panel {
		width: 100%;
		padding: 0 0 20px 14px;
	}
	.timeline > li:nth-child(odd) .timeline-panel {
		width: 100%;
		padding: 0 14px 20px 0px;
	}
	.timeline > li .line {
		display: none;
	}
	.values .owl-carousel .nav-btn {
		position: relative;
	}
}

.timelines .fas {
	font-family: "Font Awesome 5 Free", serif;
	font-size: 1.5rem;
}
.timelines {
	color: #ffffff;
	background: #373b44; /* fallback for old browsers */
	background: -webkit-linear-gradient(
		to right,
		#4286f4,
		#373b44
	); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		to right,
		#4286f4,
		#373b44
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.timelines .main-title h5,
.timelines .main-title h2,
.timelines .main-title p {
	color: #ffffff;
}
.tags li.active {
	background: #0284d0;
	border: 1px solid #0284d0;
	transition: 300ms all linear;
	color: #ffffff !important;
}
.z-0 {
	z-index: 0 !important;
}
.wp-block-pullquote {
	box-sizing: border-box;
	overflow-wrap: break-word;
	padding: 3em 0;
	text-align: center;
}
.wp-block-pullquote {
	font-size: 1.5em;
	line-height: 1.6;
}
.wp-block-pullquote cite,
.wp-block-pullquote p {
	font-size: 1em;
}
.blog .card {
	width: 100%;
	position: relative;
	background: rgb(255, 255, 255);
	padding: 20px;
	height: 100%;
	color: inherit;
}

.blog .card::after {
	z-index: -1;
	content: "";
	position: absolute;
	width: 50%;
	height: 10px;
	bottom: 15px;
	right: 0;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
	transform: rotate(5deg);
	transition: all 0.1s ease-in;
}

.blog .card::before {
	z-index: -1;
	content: "";
	position: absolute;
	width: 50%;
	height: 10px;
	bottom: 15px;
	left: 0;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
	transform: rotate(-5deg);
	transition: all 0.1s ease-in;
}

.blog .card:hover:before,
.blog .card:hover:after {
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.6);
}

.blog .card:hover:before {
	transform: rotate(-8deg);
}

.blog .card:hover:after {
	transform: rotate(8deg);
}

.card__img {
	position: relative;
	width: 100%;
	height: 175px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.card__span {
	cursor: pointer;
	font-size: 11px;
	position: absolute;
	background-color: white;
	top: 10px;
	left: 10px;
	padding: 3px 7px;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
	transition: transform 0.1s ease-in;
	user-select: none;
}

.card__span:hover {
	transform: translateX(5px);
}

.blog .card-int {
	padding: 20px 0 0 0;
}

.blog .card-int__title {
	font-weight: bold;
	font-size: 1.2rem;
	font-family: Arial, Helvetica, sans-serif;
	margin-bottom: 10px;
}

.blog .card-int__button {
	cursor: pointer;
	margin: 20px 0 0 0;
	padding: 7px 20px;
	width: 100%;
	background-color: rgb(216, 255, 234);
	border: none;
	color: black;
	position: relative;
	overflow: hidden;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
	transition: box-shadow 0.1s ease-in;
	user-select: none;
}

.blog .card-int__button:active {
	box-shadow: 0px 0px 15px rgba(0, 119, 255, 0.5);
}

.blog .card-int__button:hover::before {
	animation: effect_two 0.4s 1;
}

.blog .card-int__button::before {
	content: "More for this article";
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	background: #007c0d;
	background: linear-gradient(315deg, #68ffc0, #007c0d);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	transform: translateX(-99%);
	z-index: 1;
	animation: effect_one 10s infinite;
}

.blog .card-int__button:hover::before {
	transform: translateX(0%);
}

.excerpt {
	font-size: 14px;
}

@keyframes effect_one {
	0% {
		transform: translateX(-99%);
	}

	25% {
		transform: translateX(-90%);
	}

	50% {
		transform: translateX(-80%);
	}

	75% {
		transform: translateX(-95%);
	}

	100% {
		transform: translateX(-99%);
	}
}

@keyframes effect_two {
	to {
		transform: translateX(-1%);
	}

	from {
		transform: translateX(-99%);
	}
}
