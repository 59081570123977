.caseStudy .cbp-l-project-desc-text,
.caseStudy .cbp-l-project-details-list > li,
.cbp-l-project-details-list > div {
	font-size: 16px;
	line-height: 28px;
	font-family: "Raleway", sans-serif;
}
.caseStudy .cbp-l-project-related-title {
	font-family: "Raleway", sans-serif;
}
.caseStudy .cbp-l-project-related-wrap img,
.cbp-l-inline img {
	height: 180px;
}
@media screen and (max-width: 767px) {
	.cbp-l-project-related-item {
		max-width: 45% !important;
	}
}

.blog.page_header {
	background-image: url("../../Images/blog-bg.jpg");
	height: 80vh;
}
.blog.page_header :where(h2) {
	color: #000 !important;
}
.blog.page_header h2 {
	font-size: 74px;
}
.blog.page_header :where(h2, h5) {
	line-height: 1.2;
	font-weight: 400;
}
.blog.page_header .author {
	display: flex;
	align-items: center;
	gap: 10px;
}
.blog.page_header .author span {
	transition: all 0.01s ease-in;
}
.blog.page_header .author .desc {
	font-weight: 300;
}
.blog.page_header .author .date {
	font-size: 1.3rem;
	font-weight: 300;
}
.blog.page_header .author img {
	width: 70px;
	height: 70px;
	border-radius: 50%;
}
.news_item .news_desc,
.news_item .news_desc p {
	font-size: 20px;
	line-height: 1.875;
	color: #000;
	font-weight: 400;
}
.caseStudy .cbp-l-project-title {
	font-size: 32px;
	font-family: "Raleway", sans-serif;
	text-align: left;
}
.caseStudy > * {
	font-family: "Raleway", sans-serif;
}
.content ul {
	list-style: circle !important;
}
.content ol {
	list-style: decimal !important;
}
