#service-home .main-font {
	font-family: "Raleway", sans-serif;
}
#service-home h2,
#service-home h5 {
	line-height: 1.2;
	font-weight: 400;
}
.team-classic .image img {
	height: 280px;
	object-fit: cover;
}
#service-home {
	background-image: url("../../Images/service-bg-banner.jpg");
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	color: #ffffff;
	background-position: center center !important;
}

#service-home h2 {
	font-size: 74px;
	font-weight: 500;
}
.partners {
	background: url("../../Images/tech-partner-bg.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	color: #ffffff;
	height: 100vh;
	display: grid;
	transition: all 0.7s ease;
}
.partners .partner-overlay {
	background-color: lightgrey;
	opacity: 0.95;
}
.partners .service-partners,
#contact .service-partners {
	display: grid;
	grid-template-columns: repeat(3, 400px);
	gap: 20px;
	place-items: center;
}
#contact .service-partners {
	gap: 30px;
}
.partners .service-partners img,
#contact .service-partners img {
	object-fit: contain;
	width: auto;
	height: 200px;
}
.customerlogo {
	margin-top: 20px;
}
#cases .cbp-item img,
.ourofferings .cbp-item img {
	max-width: 100%;
	height: 100%;
	object-fit: cover;
}
#cases .cbp-caption,
#cases .cbp-caption-defaultWrap,
#cases .cbp-caption-activeWrap,
.ourofferings .cbp-caption,
.ourofferings .cbp-caption-defaultWrap,
.ourofferings .cbp-caption-activeWrap {
	height: 100%;
}

.ourofferings .cbp-item {
	height: 600px;
}
.ourofferings .cbp-caption-zoom .offer-heading:hover .cbp-caption-defaultWrap {
	transform: none;
}
.ourofferings .img-heading {
	position: absolute;
	bottom: 40px;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	padding: 20px 30px;
	margin: 0;
	color: #ffffff;
	font-size: 2.3rem;
	font-weight: 400;
}
.hover-heading {
	font-size: 2.3rem;
	font-weight: 400 !important;
	text-align: left !important;
	margin-top: 30px;
}
.service-content {
	text-align: left !important;
	font-size: 1.3rem;
	font-weight: 500;
}
.service-partners .card {
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
}
.service-contact {
	background-image: url("../../Images/service-contact2.jpg");
	padding: 120px 0;
	background-size: cover;
	width: 100%;
	color: #ffffff;
	background-position: center center;
}

.service-contact :where(h2, p) {
	color: white !important;
}
.content .learn-more-btn {
	float: left;
	color: white !important;
}
.ourofferings .main-title h5 {
	line-height: 1.5;
}
.service-case-cards .case-item {
	/* padding: 20px 20px 10px 20px; */
	margin-right: 0 !important;
	/* box-shadow: 0 0 7px 0 #a7a7a7; */
	border: 1px solid lightgray;
}
.ourofferings .icons {
	display: flex;
	align-items: center;
	gap: 20px;
	/* justify-content: space-between; */
	/* margin: 20px; */
}
.centered-item {
	grid-column: span 3;
}
@media screen and (max-width: 992px) {
	.ourofferings .heading {
		height: auto;
	}
	.partners .service-partners,
	#contact .service-partners {
		grid-template-columns: repeat(3, 300px);
	}
	#service-home h2 {
		font-size: 48px;
	}
}
@media screen and (max-width: 767px) {
	.partners .service-partners,
	.service-case-cards,
	#contact .service-partners {
		grid-template-columns: repeat(1, 100%) !important;
	}
	.partners .service-partners a {
		height: 200px;
	}
	#service-home h2,
	#service-home h5,
	#lab-home h2,
	#lab-home h5,
	#resources-home h2,
	#about-home h2,
	#resources-home h5,
	#about-home h5,
	#careers-home h2,
	#careers-home h5,
	.agile-home h2,
	.agile-home h5,
	.appDevMain-home h2,
	.appDevMain-home h5,
	.prodEng-home h2,
	.prodEng-home h5,
	.bigData-home h2,
	.bigData-home h5,
	.lowCode-home h2,
	.lowCode-home h5 {
		line-height: 1.2;
		text-align: center;
	}
	#service-home h2,
	.service-case-cards h6 {
		font-size: 36px !important;
	}
	.react-tabs__tab {
		font-size: 1.2rem !important;
	}
	.card-header .card-link {
		font-size: 1.2rem !important;
	}
	.partners,
	#contact {
		height: auto;
	}
	.about-box {
		width: 75% !important;
		margin-bottom: 1rem;
	}
}
.service-blog .owl-dots {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin: 20px;
}
/* #contact {
	background-image: repeating-linear-gradient(
			45deg,
			rgb(255, 255, 255) 0px,
			rgb(255, 255, 255) 20px,
			transparent 20px,
			transparent 21px
		),
		repeating-linear-gradient(
			135deg,
			rgb(255, 255, 255) 0px,
			rgb(255, 255, 255) 20px,
			transparent 20px,
			transparent 21px
		),
		linear-gradient(90deg, hsl(168, 96%, 25%), hsl(168, 96%, 25%));
} */
.customerlogo {
	padding: 2rem 20px;
	border-radius: 10px;
	text-align: center;
	width: 100%;
	height: 100%;
	transition: all 1s ease-out;
}
.customerlogo img {
	margin-bottom: 1rem;
}
.customerlogos a {
	width: 100%;
	height: 100%;
}
.customerlogo .subtext {
	opacity: 0;
}
.customerlogo:hover .subtext {
	opacity: 1;
}
.customerlogo:hover {
	padding: 2rem 20px;

	border-radius: 10px;
}
.continulink:hover {
	background-color: #a8bc4f;
}
.modria:hover {
	background-color: #eb312e;
}
.earnin:hover {
	background-color: #2cd5b2;
}
.crosstalk:hover {
	background-color: #9d3ba9;
}
.fund:hover {
	background-color: #fd9332;
}
.rank:hover {
	background-color: #666666;
}
.adminibot:hover {
	background-color: #139ed5;
}
.krishi:hover {
	background-color: #5ab544;
}
.cambi:hover {
	background-color: #4193cd;
}
.continulink:hover {
	background-color: #a8bc4f;
}
.saipem:hover {
	background-color: #1c4b5a;
}
.interim:hover {
	background-color: #f32542;
}
.ir:hover {
	background-color: #ee2e24;
}
.viking:hover {
	background-color: #ffe33b;
}
.schneider:hover {
	background-color: #009e4d;
}
.NUS:hover {
	background-color: #004282;
}
.beroe:hover {
	background-color: #838383;
}
.verizon:hover {
	background-color: #cf0408;
}
.fund:hover {
	background-color: #fd9332;
}
.em:hover {
	background-color: #6d6e71;
}
.synapse:hover {
	background-color: #f07f29;
}
#big-data .aerospike {
	width: 200px !important;
	height: 100px !important;
}
.icons img {
	aspect-ratio: 3/2;
}
.icons .mendix {
	width: 190px;
	height: 100px !important;
}
.icons .microsoft-pow {
	width: 200px;
	height: 60px !important;
}
