* {
	font-family: "Raleway", sans-serif;
}
#careers-home .main-font {
	font-family: "Raleway", sans-serif;
}
#careers-home h2 {
	line-height: 1.2;
	font-weight: 500;
}
#careers-home h5 {
	line-height: 1.2;
	font-weight: 400;
}
#careers-home {
	background-image: url("../../Images/people.png");
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	color: #ffffff;
	background-position: 0 91% !important;
}
#careers-home video {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	object-fit: cover;
}

#careers-home h2 {
	font-size: 74px;
}

.container.border-left {
	border-left: 1px solid gray;
	border-right: 1px solid gray;
}
.pin {
	width: 0.729166vw;
	height: 0vw;
	border-radius: 0;
	background: #2789ea;
	position: absolute;
	transform: rotate(0deg);
	left: -1.375vw;
	top: max(0vw);
	margin-top: 1.5rem;
}
.pin.show {
	height: 5vw;
}
.pin-2 {
	left: auto;
	right: -1.375vw;
}
.careers-about-section {
	flex-basis: 50%;
}
@media screen and (max-width: 767px) {
	.careers-about-section {
		flex-basis: 100%;
	}
	.careers.service-contact .main-title {
		text-align: center !important;
	}
}

.careers-about h1 {
	color: black;
	font-weight: 600;
	font-family: "Raleway", sans-serif;
}
.jobs {
	background-color: #8bc6ec;
	background-image: linear-gradient(135deg, #8bc6ec 0%, #9599e2 100%);
}
.tabset-list img {
	height: 50px;
}
/* accordion */
#accordion {
	font-family: "Raleway", sans-serif;
}
#accordion .card {
	margin-bottom: 15px;
	padding: 10px;
	border: none;
	border-radius: 20px;
}

#accordion .card:last-child {
	margin-bottom: 0;
}

.card-header {
	padding: 0 !important;
	background-color: transparent !important;
	border-bottom: none !important;
}

.card-header .card-link {
	display: block;
	padding: 1.75rem 1.25rem;
	background: #fff;
	border: none;
	font-size: 24px;
	font-weight: 700;
	color: #002e5b;
}

.card-header .card-link:before {
	content: "\f107";
	float: right;
	background: none;
	font-family: "Font Awesome 5 Free", serif;
	font-weight: 700;
	transition: all 0s !important;
	border: none;
	vertical-align: baseline;
	width: auto;
	height: auto;
	line-height: 1;
}

.card-header .card-link.collapsed:before {
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	transform: rotate(180deg);
}

.card-body {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	padding-top: 1.85rem;
	padding-bottom: 1.35rem;
}

.card-body p:last-child {
	margin-bottom: 0;
}
.card-body .apply-btn {
	display: grid;
	place-items: center;
	width: 100%;
}
.card-body .apply-btn a {
	text-decoration: none;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 1px;
	background: linear-gradient(to left, #fbb042, #ef712b);
	padding: 12px 35px;
	display: inline-block;
	font-size: 16px;
	font-weight: 400;
	border-radius: 5px;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
}
.tech-list li {
	padding: 5px;
}
.tech-list li::before {
	content: "\f30b";
	float: left;
	background: none;
	font-family: "Font Awesome 5 Free", serif;
	font-weight: 700;
	transition: all 0s !important;
	border: none;
	vertical-align: baseline;
	width: auto;
	height: auto;
	font-size: 1.2rem;
	margin-right: 15px;
	text-align: center;
	color: #d91a60;
}
.btn-outline-info:hover {
	border-color: #d91a60 !important;
	background: linear-gradient(
		102.57deg,
		#d0121e,
		#f84d9f 100%,
		#f84d9f 0
	) !important;
}
.life {
	background: #f0f0f0;
}
.life .block-1 {
	background-image: url("../../Images/about-1.png");
	float: left !important;
}
.life .block-2 {
	background-image: url("../../Images/about-2.jpg");
}
.life .block-3 {
	background-image: url("../../Images/about-3.jpg");
	float: left !important;
}
.life .people-block {
	width: 450px;
	height: 450px;
	background-size: cover;
	border-radius: 5px;
	background-position: 45% 0;
	float: right;
}
#lfdivmobile {
	display: none;
}
.life .paper_effect_div {
	padding: 0 0 5% 0;
	margin-left: 0;
	margin-right: 0;
}
.tech_head {
	/* padding-top: 3%; */
	margin-bottom: 5% !important;
}
.life .content {
	margin-left: auto;
	margin-right: auto;
	/* margin-top: 60px; */
	font-size: 20px;
}
.life .content div.min-center {
	font-size: 1.3rem;
	line-height: 1.5;
}
.life .content h3 {
	font-size: 1.7rem;
	line-height: 1.2;
	font-weight: 500;
}
.careers.service-contact {
	background-image: url("../../Images/careers-last.jpg") !important;
	margin-top: 0 !important;
	height: 100vh;
	display: grid;
	place-items: center;
}
.careers.service-contact .bg-overlay {
	background-color: black !important;
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
	.life .people-block {
		width: 250px;
		height: 250px;
		margin: 0 auto;
		float: none !important;
	}
	.life #lfdiv {
		flex-direction: column-reverse !important;
	}
}
.job-btn {
	background-color: green !important;
}
