#lab-home .main-font {
	font-family: "Raleway", sans-serif;
}
#lab-home h2,
#lab-home h5 {
	line-height: 1.2;
	font-weight: 400;
}
#lab-home {
	background-image: url("../../Images/labs-bg-2.jpg");
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	color: #ffffff;
	background-position: center center !important;
}

#lab-home video {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	object-fit: cover;
}
#lab-home h2 {
	font-size: 74px;
}
.about.lab-about {
	/* background-image: radial-gradient(white, #fcfafb, #c8d3d6); */
	background-image: linear-gradient(
			158deg,
			rgba(84, 84, 84, 0.03) 0%,
			rgba(84, 84, 84, 0.03) 20%,
			rgba(219, 219, 219, 0.03) 20%,
			rgba(219, 219, 219, 0.03) 40%,
			rgba(54, 54, 54, 0.03) 40%,
			rgba(54, 54, 54, 0.03) 60%,
			rgba(99, 99, 99, 0.03) 60%,
			rgba(99, 99, 99, 0.03) 80%,
			rgba(92, 92, 92, 0.03) 80%,
			rgba(92, 92, 92, 0.03) 100%
		),
		linear-gradient(
			45deg,
			rgba(221, 221, 221, 0.02) 0%,
			rgba(221, 221, 221, 0.02) 14.286%,
			rgba(8, 8, 8, 0.02) 14.286%,
			rgba(8, 8, 8, 0.02) 28.572%,
			rgba(52, 52, 52, 0.02) 28.572%,
			rgba(52, 52, 52, 0.02) 42.858%,
			rgba(234, 234, 234, 0.02) 42.858%,
			rgba(234, 234, 234, 0.02) 57.144%,
			rgba(81, 81, 81, 0.02) 57.144%,
			rgba(81, 81, 81, 0.02) 71.42999999999999%,
			rgba(239, 239, 239, 0.02) 71.43%,
			rgba(239, 239, 239, 0.02) 85.71600000000001%,
			rgba(187, 187, 187, 0.02) 85.716%,
			rgba(187, 187, 187, 0.02) 100.002%
		),
		linear-gradient(
			109deg,
			rgba(33, 33, 33, 0.03) 0%,
			rgba(33, 33, 33, 0.03) 12.5%,
			rgba(147, 147, 147, 0.03) 12.5%,
			rgba(147, 147, 147, 0.03) 25%,
			rgba(131, 131, 131, 0.03) 25%,
			rgba(131, 131, 131, 0.03) 37.5%,
			rgba(151, 151, 151, 0.03) 37.5%,
			rgba(151, 151, 151, 0.03) 50%,
			rgba(211, 211, 211, 0.03) 50%,
			rgba(211, 211, 211, 0.03) 62.5%,
			rgba(39, 39, 39, 0.03) 62.5%,
			rgba(39, 39, 39, 0.03) 75%,
			rgba(55, 55, 55, 0.03) 75%,
			rgba(55, 55, 55, 0.03) 87.5%,
			rgba(82, 82, 82, 0.03) 87.5%,
			rgba(82, 82, 82, 0.03) 100%
		),
		linear-gradient(
			348deg,
			rgba(42, 42, 42, 0.02) 0%,
			rgba(42, 42, 42, 0.02) 20%,
			rgba(8, 8, 8, 0.02) 20%,
			rgba(8, 8, 8, 0.02) 40%,
			rgba(242, 242, 242, 0.02) 40%,
			rgba(242, 242, 242, 0.02) 60%,
			rgba(42, 42, 42, 0.02) 60%,
			rgba(42, 42, 42, 0.02) 80%,
			rgba(80, 80, 80, 0.02) 80%,
			rgba(80, 80, 80, 0.02) 100%
		),
		linear-gradient(
			120deg,
			rgba(106, 106, 106, 0.03) 0%,
			rgba(106, 106, 106, 0.03) 14.286%,
			rgba(67, 67, 67, 0.03) 14.286%,
			rgba(67, 67, 67, 0.03) 28.572%,
			rgba(134, 134, 134, 0.03) 28.572%,
			rgba(134, 134, 134, 0.03) 42.858%,
			rgba(19, 19, 19, 0.03) 42.858%,
			rgba(19, 19, 19, 0.03) 57.144%,
			rgba(101, 101, 101, 0.03) 57.144%,
			rgba(101, 101, 101, 0.03) 71.42999999999999%,
			rgba(205, 205, 205, 0.03) 71.43%,
			rgba(205, 205, 205, 0.03) 85.71600000000001%,
			rgba(53, 53, 53, 0.03) 85.716%,
			rgba(53, 53, 53, 0.03) 100.002%
		),
		linear-gradient(
			45deg,
			rgba(214, 214, 214, 0.03) 0%,
			rgba(214, 214, 214, 0.03) 16.667%,
			rgba(255, 255, 255, 0.03) 16.667%,
			rgba(255, 255, 255, 0.03) 33.334%,
			rgba(250, 250, 250, 0.03) 33.334%,
			rgba(250, 250, 250, 0.03) 50.001000000000005%,
			rgba(231, 231, 231, 0.03) 50.001%,
			rgba(231, 231, 231, 0.03) 66.668%,
			rgba(241, 241, 241, 0.03) 66.668%,
			rgba(241, 241, 241, 0.03) 83.33500000000001%,
			rgba(31, 31, 31, 0.03) 83.335%,
			rgba(31, 31, 31, 0.03) 100.002%
		),
		linear-gradient(
			59deg,
			rgba(224, 224, 224, 0.03) 0%,
			rgba(224, 224, 224, 0.03) 12.5%,
			rgba(97, 97, 97, 0.03) 12.5%,
			rgba(97, 97, 97, 0.03) 25%,
			rgba(143, 143, 143, 0.03) 25%,
			rgba(143, 143, 143, 0.03) 37.5%,
			rgba(110, 110, 110, 0.03) 37.5%,
			rgba(110, 110, 110, 0.03) 50%,
			rgba(34, 34, 34, 0.03) 50%,
			rgba(34, 34, 34, 0.03) 62.5%,
			rgba(155, 155, 155, 0.03) 62.5%,
			rgba(155, 155, 155, 0.03) 75%,
			rgba(249, 249, 249, 0.03) 75%,
			rgba(249, 249, 249, 0.03) 87.5%,
			rgba(179, 179, 179, 0.03) 87.5%,
			rgba(179, 179, 179, 0.03) 100%
		),
		linear-gradient(
			241deg,
			rgba(58, 58, 58, 0.02) 0%,
			rgba(58, 58, 58, 0.02) 25%,
			rgba(124, 124, 124, 0.02) 25%,
			rgba(124, 124, 124, 0.02) 50%,
			rgba(254, 254, 254, 0.02) 50%,
			rgba(254, 254, 254, 0.02) 75%,
			rgba(52, 52, 52, 0.02) 75%,
			rgba(52, 52, 52, 0.02) 100%
		),
		linear-gradient(90deg, #ffffff, #ffffff);
}
.prod-card {
	display: grid;
	width: 300px;
	height: 200px;
	box-sizing: border-box;
	padding: 2em;
	border-radius: 3px;
	color: #000;
	line-height: 25px;
	box-shadow: 1px 1px 10px #ccc;
	/* margin-right: auto;
	margin-left: auto; */
	place-items: center;
	cursor: pointer;
	text-decoration: none;
}
.prod-card h5 {
	font-weight: 500;
	font-size: 1.7rem;
}

.prod-card .methodical {
	width: 100px;
}
.prod-card .juno-img {
	width: 70px;
}
.startUp {
	/* height: 100vh; */
	background-image: url("../../Images/startup-bg-2-copy.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	color: #ffffff;
	background-position: 100% 0%;
}
.startUp .box-1 {
	background-color: rgba(0, 0, 0, 0.7);
}
.startUp .main-title h2,
.startUp .main-title p {
	color: #ffffff !important;
}
.startUp .prod-card {
	width: 100% !important;
	font-size: 1rem;
	background-color: transparent;

	box-shadow: none;
}
.startUp .prod-card img {
	width: 70px;
}
.startUp .prod-card h5 {
	font-size: 1rem;
	color: #ffffff;
}
.startUp .talk-to-us-parent {
	margin-top: 2rem;
	position: relative;
}

.methodical-list li {
	padding: 5px;
	font-size: 1.025rem;
}
.methodical-list li::before {
	content: "\f30b";
	float: left;
	background: none;
	font-family: "Font Awesome 5 Free", serif;
	font-weight: 700;
	transition: all 0s !important;
	border: none;
	vertical-align: baseline;
	width: auto;
	height: auto;
	font-size: 1.2rem;
	margin-right: 15px;
	text-align: center;
}
.methodical-details .methodical-logo {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.methodical-logo img {
	width: 50px;
	margin-bottom: 10px;
}
.methodical-details {
	background-color: rgba(0, 0, 0, 0.2);
	background-image: url("../../Images/logos/methodical-bg.jpg");
	height: 80vh;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	color: #ffffff !important;
	background-position: center center;
}
.methodical-details h2,
.methodical-details h5,
.methodical-details p {
	color: #ffffff !important;
}
.methodical-details .btn-setting {
	float: left;
	margin-top: 20px;
}
.juno-details .juno-logo {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	margin-bottom: 20px;
}
.juno-details .juno-logo h5 {
	font-size: 2.8rem;
	font-weight: 600;
	line-height: 2;
	margin-bottom: 0 !important;
}
.juno-logo img {
	width: 45px;
	height: 100%;
}
.juno-details .juno-points {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-top: 20px;
	gap: 10px;
}
.juno-details .juno-points span {
	font-size: 7rem;
	font-weight: 400;
	color: #9c27b0;
	line-height: 0.8;
	margin-bottom: 0 !important;
	margin-left: -8px;
}
.juno-details .juno-points p {
	font-size: 0.9rem;
	font-weight: 300;
	color: #9c27b0 !important;
	margin-bottom: 0;
}
.about-img.juno {
	width: 100%;
	height: 100%;
	aspect-ratio: 3/2;
}
@media screen and (max-width: 767px) {
	.methodical-details,
	.juno-details {
		height: auto;
	}
	.startUp {
		background-position: 75% 0%;
	}
}
