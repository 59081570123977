/* .people-carousel {
	width: 80%;
	margin: auto;
	margin-top: 50px;
}

.person-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
}

.round-image {
	width: 100px !important ;
	height: 100px;
	object-fit: cover;
	border-radius: 50%;
	margin-bottom: 10px;
}

.person-details {
	text-align: center;
}
.slick-dots {
	bottom: 20px;
}

.slick-dots li button::before {
	font-size: 12px;
	color: #fff;
} */
/* body {
    align-items: center;
    background: #E3E3E3;
    display: flex;
    height: 100vh;
    justify-content: center;
  } */
@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-250px * 7));
	}
}
.slider {
	background: white;
	/* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125); */
	height: 70vh;
	margin: auto;
	/* overflow: hidden; */
	position: relative;
	width: 100%;
	/* width: calc(100px * 14); */

	padding: 80px 0 50px 0;
}
/* .slider::before,
.slider::after {
	background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
	content: "";
	height: 300px;
	position: absolute;
	width: 200px;
	z-index: 2;
} */
.slider::after {
	right: 0;
	top: 0;
	transform: rotateZ(180deg);
}
.slider::before {
	left: 0;
	top: 0;
}
.round-image:hover .slider .slide-track > div:nth-child(2) {
	animation-play-state: paused !important;
}
.slider .slide-track > div:nth-child(1) {
	overflow: visible !important;
}
.slider .slide-track {
	/* animation: scroll 120s linear infinite; */
	/* gap: 10px; */
	/* width: calc(100px * 52); */
	/* column-count: 74;
	column-width: 120px;
	column-gap: 20px;
	position: relative;
	height: 100%;
	left: 0;
	top: 0; */
	/* position: absolute; */
	/* left: 0;
	top: 0; */
	/* width: 100%; */
	height: 100%;
	/* box-shadow: inset 0 0 0 2px #404040; */
	/* margin: auto;
	margin-top: 50px;
	max-width: 1000px;
	display: grid;
	grid-template-columns: repeat(8, 1fr);
	grid-auto-rows: 200px;
	grid-gap: 14px;
	padding-bottom: 50px; */
}
.slider .slide {
	height: 70px;
	width: 70px;
	/* transform: translate(10px, 10px); */
	/* position: absolute; */
	/* display: flex;
	position: relative;
	width: 240px;
	height: 265px;
	background-color: #424242;
	-webkit-clip-path: polygon(
		50% 0%,
		100% 25%,
		100% 75%,
		50% 100%,
		0% 75%,
		0% 25%
	);
	clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%); */
}
.slider .slide img {
	object-fit: cover;
	border-radius: 50%;
	width: 100%;
	height: 100% !important;
	/* animation: moving linear infinite; */

	/* scale: 1.3; */
	/* transform: translate(20px, 20px) scale(0.75); */
}
/* .animation-none {
	animation-play-state: paused !important;
} */
.round-image:hover {
	cursor: pointer;
	scale: 1.6 !important;
	z-index: 99999999;
}
.person-details {
	position: absolute;
	/* top: -4px;
	bottom: -4px;
	right: -4px;
	left: -4px;
	background-color: white; */
	display: flex;
	flex-direction: column;
	/* z-index: 999999; */
	width: 250px;
	padding: 10px;
	/* box-shadow: 0 0 15px lightgray; */
	top: 80px; /* Position above the image */
	left: 50%;
	transform: translateX(-50%);
	background-color: rgba(255, 255, 255, 1);
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	opacity: 1;
	transition: top 0.3s ease, opacity 0.3s ease;
	color: black;
	z-index: 9999999;
}
.person-details h3 {
	font-size: 1rem !important;
}
.person-details p {
	font-size: 0.8rem;
}
.show {
	/* top: -100%; */
	opacity: 1;
}
/* .slide:first-child {
	grid-row-start: 1;
	grid-column: 2 / span 2;
}

.slide:nth-child(2) {
	grid-row-start: 1;
	grid-column: 4 / span 2;
}

.slide:nth-child(3) {
	grid-row-start: 1;
	grid-column: 6 / span 2;
}

.slide:nth-child(4) {
	grid-row-start: 2;
	grid-column: 1 / span 2;
}

.slide:nth-child(5) {
	grid-row-start: 2;
	grid-column: 3 / span 2;
}

.slide:nth-child(6) {
	grid-row-start: 2;
	grid-column: 5 / span 2;
}

.slide:nth-child(7) {
	grid-row-start: 2;
	grid-column: 7 / span 2;
}

.slide:nth-child(8) {
	grid-row-start: 3;
	grid-column: 2 / span 2;
}

.slide:nth-child(9) {
	grid-row-start: 3;
	grid-column: 4 / span 2;
}

.slide:nth-child(10) {
	grid-row-start: 3;
	grid-column: 6 / span 2;
} */
.slide-track > .box {
	position: absolute;
	left: 50%;
	top: 50%;
	background-color: #ffffff;
	transition: left 400ms ease-in-out, top 400ms ease-in-out;
}
.slide-track > .box.dim0 {
	width: 60px;
	height: 60px;
	margin-left: -20px;
	margin-top: -15px;
	/* background-color: #804000;
	box-shadow: inset 0 0 0 2px #c08000; */
}
.slide-track > .box.dim1 {
	width: 60px;
	height: 60px;
	margin-left: -7px;
	margin-top: -24px;
	/* background-color: #400080;
	box-shadow: inset 0 0 0 2px #a000f0; */
}
.slide-track > .box.dim2 {
	width: 60px;
	height: 60px;
	margin-left: -11px;
	margin-top: -11px;
	/* background-color: #600060;
	box-shadow: inset 0 0 0 2px #c000d0; */
}
@keyframes moving {
	0% {
		transform: rotate(319deg);
	}

	100% {
		transform: rotate(-41deg);
	}
}
