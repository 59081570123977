@import url("https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700,800|Open+Sans:300,400,600,700");

.slide .heading {
	font-weight: 400 !important;
	font-size: 74px !important;
}
.slide .about-p {
	font-weight: 300 !important;
}
#home .talk-to-us a {
	padding: 15px 40px !important;
}
.talk-to-us a {
	border: 3px solid white;
	height: 55px;
	text-transform: capitalize;
	font-size: 18px !important;
	font-weight: 600 !important;
	text-align: center !important;
	padding: 12px 40px;
}
.talk-to-us .learn-more {
	border: none;
}
.talk-to-us.testimonial {
	position: absolute;
	/* left: 50%; */
	bottom: 5%;
}
.main-title p {
	font-size: 22px;
	color: #000;
	font-family: "Raleway", sans-serif;
	font-weight: 300;
}
.main-title h2 {
	font-weight: 500 !important;
}

.tech-partners {
	width: 100%;
	height: 150px;
	z-index: 30;
	position: absolute;
	background-color: rgba(0, 0, 0, 0.4) !important;
	bottom: 0;
}
.tech-partners h4 {
	font-size: 1.2rem;
	font-family: "Raleway";
	color: white;
	margin-left: 1rem;
}
.tech-partners .logos {
	display: flex;
	align-items: center;
	gap: 30px;
	flex-wrap: wrap;
}
.tech-partners .logos .aws {
	height: 40px !important;
}
.tech-partners .logos img,
.slide3 .logos img {
	width: 150px !important;
	height: 100px !important;
	object-fit: contain;
	aspect-ratio: 3/2;
	flex-basis: 70px;
}
.slide3 .logos {
	gap: 10px;
	color: #ffffff;
	flex-wrap: wrap;
	flex-basis: 50%;
}
.slide3 span {
	flex-basis: 100%;
}
.slide3 .logos img {
	width: 100px !important;
	height: 100px !important;
}
.slide3 .logos .logo {
	flex-basis: 200;
}
.slide3 .logos .p-logo img {
	width: 100px !important;
	height: 60px !important;
}
.slide3 .logos .methodical img {
	width: 50px !important;
	height: 50px !important;
}
.slide3 .logos .methodical h4 {
	font-weight: 600;
}
.news-item {
	position: relative;
	overflow: visible;
	text-align: left;
	cursor: pointer;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	background-color: #ffffff;
	color: #000;
	display: block;
	height: 440px;
	box-shadow: 0px 0px 6px lightgray !important;
}
.news-item img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: all 0.5s ease 0.2s;
	border-radius: 6px;
	box-shadow: 0px 0px 10px lightgray;
}
#cases .image-holder-gallary {
	height: 250px !important;
}
.news-item .author {
	display: flex;
	align-items: center;
	margin-top: 15px;
	gap: 10px;
}
.news-item .author img {
	width: 36px;
	height: 36px;
	border-radius: 100%;
	object-fit: cover;
	transition: all 0.5s ease 0.2s;
}
.news-item .author .author-name {
	font-size: 0.9rem;
	font-weight: 500;
	line-height: 1.4;
}
/* .news-item:hover { */
/* transform: translateY(-10px); */
/* box-shadow: 0 20px 50px rgba(0, 0, 0, 0.14) !important; */
/* background-color: #d9afd9;
	background-image: linear-gradient(0deg, #d9afd9 0%, #97d9e1 100%); */
/* } */
.news-item:hover .news-title {
	font-weight: 600 !important;
}

.image-holder-gallary {
	overflow: hidden;
	height: 220px;
	padding: 1rem !important;
	position: absolute;
	top: -2rem;
	border-radius: 7px;
	width: 100%;
}

.news-item .news-text-box {
	padding: 20px 0px;
	background-color: transparent;
}

.news-item .news-title {
	margin: 14px 0;
	font-size: 24px;
	font-weight: bold;
}

/* #cases { */
/* background: #c9d6ff;
	background: -webkit-linear-gradient(to right, #e2e2e2, #c9d6ff);
	background: linear-gradient(to right, #e2e2e2, #c9d6ff); */
/* } */
#cases .news-item .news-text-box {
	padding: 20px 0;
}

.para {
	font-size: 14px;
	font-weight: 400;
	line-height: 1.4;
	font-family: "Raleway", sans-serif;
	margin: 0;
}
.about h2 {
	font-size: 40px;
}
.about-p {
	font-size: 1.563rem;
	line-height: 1.5 !important;
	font-weight: 500;
	/* color: #000; */
	/* color: #ffffff !important; */
}
.about-p.home {
	font-size: 37px;
}
.about {
	background-image: radial-gradient(white, #eef7f4, #d1eae4);
	height: 100vh;
	display: grid;
	place-items: center;
}

.blog .news-item .news-title {
	margin-top: 0 !important;
}
#values {
	background: url("../../Images/pattern.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	color: #ffffff;
	/* height: 100vh; */
	display: grid;
	place-items: center;
	transition: all 0.7s ease;
}
.values .value-card {
	/* padding: 50px 50px 50px 100px; */
	transition: all 0.7s ease;
	position: relative;
	/* background-color: #ff3cac;
	background-image: linear-gradient(
		228deg,
		#ff3cac 0%,
		#784ba0 44%,
		#2b86c5 100%
	); */
	transition: all 0.7s ease;
}
/* .values:hover {
	background-color: #ff3cac;
	background-image: linear-gradient(
		228deg,
		#ff3cac 0%,
		#784ba0 44%,
		#2b86c5 100%
	);
} */
/* .value-card:has(a:hover) :where(.values) { */
/* .values:hover .value-card {
	background-color: white;
	background-image: none;
} */
/* .values .value-card:has(a:hover) :where(.values-new .about-p, h2, a) {
	color: white !important;
} */
.values-new .main-title h5 {
	/* font-size: 16px; */
	line-height: 1.5;
	color: black;
	font-family: "Raleway", sans-serif;
	font-weight: 300;
}
/* #values .left-circle {
	position: relative;
	background-image: url("../Images/our-mission.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	height: 400px;
	width: 400px;
	border-radius: 50%;
	z-index: 2;
	padding: 65px 50px;
	color: #ffffff;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
#values .left-circle h5,
#values .right-circle h5 {
	color: #ffffff;
}
#values .right-circle {
	background-image: url("../Images/vision.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	height: 400px;
	width: 400px;
	border-radius: 50%;
	position: relative;
	right: 50px;
	z-index: 2;
	padding: 65px 50px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
#values .left-circle:hover,
#values .right-circle:hover {
	transform: scale(1.2);
	transition: all 0.5s ease 0.2s;
	cursor: pointer;
	z-index: 10;
}
.left-circle .about-p,
.right-circle .about-p {
	font-size: 1rem;
} */

/* Nav bar dropdown */
.navbar-top-default .navbar-nav .nav-link {
	margin: 16px 1rem;
}
.nav-bottom-line .navbar-nav .dropdown-toggle::after {
	content: "\f107";
	display: inline-block;
	background: none;
	font-family: "Font Awesome 5 Free", serif;
	font-weight: 900;
	transition: all 0s !important;
	border: none;
	vertical-align: baseline;
	width: auto;
	height: auto;
	line-height: 1;
	margin-left: 0.555em;
}
.navbar-nav .nav-link.dropdown-toggle::before {
	display: none;
}
.navbar-nav .dropdown-menu {
	z-index: 5;
	background: #fff;
	border: none;
	border-radius: 0;
	padding: 18px 0;
	-webkit-box-shadow: 0 13px 25px -12px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0 13px 25px -12px rgba(0, 0, 0, 0.25);
	box-shadow: 0 13px 25px -12px rgba(0, 0, 0, 0.25);
	left: 0;
	top: auto;
	border-top: 1px solid #efefef;
	margin-top: 0;
	cursor: default;
	font-family: "Raleway", sans-serif;
}
.menu-top .navbar-nav .dropdown-menu {
	-webkit-box-shadow: 0 0 25px -10px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0 0 25px -10px rgba(0, 0, 0, 0.25);
	box-shadow: 0 0 25px -10px rgba(0, 0, 0, 0.25);
	bottom: 68px;
}
.dropdown-menu.right-show {
	left: auto !important;
	right: 0 !important;
}

.dropdown-menu .dropdown-item {
	background: transparent;
	font-weight: normal;
	font-size: 1rem;
	padding: 0 1.4rem 0 1.4rem;
	line-height: 40px;
	font-family: "Raleway", sans-serif;
}

a.dropdown-toggle::after {
	transition: all 0s;
}

.dropdown-menu .dropdown-item,
.dropdown-menu .dropdown-title {
	color: #26313c;
}

.dropdown-item.active {
	/* color: #ffb902; */
	font-weight: 600;
}
.navbar-nav .nav-link:hover,
.navbar-nav .nav-link.active,
.navbar .dropdown:hover .dropdown-toggle,
.dropdown-menu .dropdown-item:hover,
.dropdown-menu.megamenu .dropdown-item:hover {
	/* color: #ffb902 !important; */
	font-weight: 600;
}

.bg-yellow .dropdown-menu .dropdown-item:hover,
.bg-yellow .dropdown-menu.megamenu .dropdown-item:hover {
	color: #026cb8 !important;
}
.bg-yellow .navbar-nav .nav-link:hover,
.bg-yellow .navbar-nav .nav-link.active {
	color: #fff !important;
}

.dropdown-menu .dropdown-title {
	margin: 1.5rem 0 0.5rem 1rem;
	font-size: 19px;
}

.sideNavPages .nav-item {
	display: block;
	margin: 5px 0;
}
.side-nav .navbar-nav .sideNavPages .nav-link {
	padding: 5px 0 !important;
	min-height: auto;
}
.sideNavPages .nav-link {
	font-size: 1rem !important;
	margin-left: 30px !important;
}
.collapsePagesSideMenu i {
	font-size: 1rem;
}
@media screen and (max-width: 992px) {
	.sideNavPages .nav-link {
		font-size: 1rem !important;
	}
	.blog .news-item {
		height: 420px;
		margin: 0;
		flex-direction: column;
	}
	.about-p {
		font-size: 1.2rem !important;
	}
	.about,
	.values {
		height: auto;
	}
	.slide3 .logos {
		flex-basis: 100%;
	}
}
@media screen and (min-width: 992px) {
	.navbar-nav .dropdown {
		position: static;
	}
	.navbar .dropdown-menu {
		display: block;
		opacity: 0.4;
		visibility: hidden;
		min-width: 260px;
		transform: scale(1, 0);
		transform-origin: 0 0;
	}
	.navbar.menu-top .dropdown-menu {
		transform-origin: 100% 100%;
	}
	.dropright .dropdown-menu {
		display: block;
		opacity: 0.4;
		visibility: hidden;
		transform: scale(0, 1);
		transform-origin: 0 0;
	}
	.navbar-nav .dropdown:hover > .dropdown-menu,
	.dropright:hover > .dropdown-menu {
		opacity: 1;
		visibility: visible;
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1);
	}
}

/* case item */
.case-item {
	flex-basis: calc(32% - 20px);
	margin-right: 24px;
}
.case-item h6 {
	font-size: 2.3rem;
	font-weight: 400 !important;
	font-family: "Raleway", sans-serif;
	line-height: 130%;
	/* color: #d91a60 !important; */
}
.case-item .para {
	color: #000;
	font-size: 1.3rem;
	font-weight: 500;
}
.learn-btn {
	color: #d91a60 !important;
	font-size: 1.2rem !important;
}
.case-item .date,
.news-item .date {
	text-align: left;
	color: #9c9c9c;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	opacity: 0.8;
}
/* .case-item .date::before,
.news-item .date::before {
	content: "\f073";
	background: none;
	font-family: "Font Awesome 5 Free", serif;
	font-weight: 700;
	transition: all 0s !important;
	border: none;
	vertical-align: baseline;
	width: auto;
	height: auto;
	line-height: 1;
	margin-right: 5px;
} */

/* ===================================
 values
====================================== */

/* .team {
	background-image: url("../images/team-bg.jpg");
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	width: 95%;
	margin-left: 5%;
}

.team h2 {
	font-size: 48px;
	font-weight: bold;
}

.parallax-box {
	text-align: center;
}

.parallax-box h2 {
	font-size: 2.5rem;
	margin-bottom: 10px;
	font-weight: bold;
}

.parallax-box h5 {
	font-weight: normal;
	font-size: 16px;
} */

#team-slider {
	text-align: center;
	overflow: visible;
}

.team-box {
	text-align: center;
	display: inline-block;
	width: 100%;
	/* padding: 10px; */
	background-color: #ffffff;
	height: 330px;
	-webkit-transition: 0.5s ease;
	-o-transition: 0.5s ease;
	transition: 0.5s ease;
	position: relative;
	-webkit-transform: scale(0.7);
	-ms-transform: scale(0.7);
	transform: scale(0.7);
	box-shadow: 0 0 7px 0 #a7a7a7;
	border-radius: 10px;
	z-index: 99999;
	font-family: "Raleway", sans-serif;
}

.team-box .team-image {
	position: relative;
	overflow: hidden;
}
.team-box .team-image img {
	border-radius: 10px 10px 0 0;
	height: 250px;
}
.team-box .team-text {
	text-align: center;
	font-size: 14px;
	padding: 1.3rem;
}

.team-box .team-text span,
.team-box .team-text .team-social {
	opacity: 0;
	transition: 0.25ms;
}

.team-box .team-text h5 {
	font-size: 22px;
	color: #232728;
	font-weight: 600;
}

.team-box .team-text span {
	color: #212331;
	font-size: 14px;
	font-weight: normal;
}

#team-slider .owl-item.center .item {
	height: auto;
	transform: scale(1);
}

#team-slider .owl-item.center .team-box .team-text h5 {
	font-size: 24px;
}

#team-slider .owl-item.center .item .team-text span,
#team-slider .owl-item.center .item .team-text .team-social {
	opacity: 1;
	transition: 0.5s ease;
}

.team-social ul {
	margin: 0;
}

.team-social li {
	display: inline-block;
}

.team-social a {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 40px;
	border-radius: 50px;
	font-size: 15px;
	margin: 5px 0;
	color: #232728;
	border: 1px solid transparent;
	-webkit-transition: background-color 0.2s ease-in-out;
	-moz-transition: background-color 0.2s ease-in-out;
	-ms-transition: background-color 0.2s ease-in-out;
	-o-transition: background-color 0.2s ease-in-out;
	transition: background-color 0.2s ease-in-out;
}
.owl-carousel .owl-stage-outer {
	height: 100%;
}
/* Owl carousel navigation */
.owl-carousel .nav-btn {
	height: 47px;
	position: absolute;
	width: 26px;
	cursor: pointer;
	top: 35% !important;
}

.owl-carousel .owl-prev.disabled,
.owl-carousel .owl-next.disabled {
	pointer-events: none;
	opacity: 0.2;
}

.owl-carousel .prev-slide {
	left: -33px;
	color: black;
}
.owl-carousel .prev-slide .fa {
	font-size: 32px;
}
/* .owl-carousel .prev-slide::before {
	content: "\f104";
	display: inline-block;
	background: none;
	font-family: "Font Awesome 5 Free", serif;
	font-weight: 600;
	transition: all 0s !important;
	border: none;
	vertical-align: baseline;
	width: auto;
	height: auto;
	line-height: 1;
	font-size: 5rem;
	color: black;
}
.owl-carousel .next-slide::before {
	content: "\f105";
	display: inline-block;
	background: none;
	font-family: "Font Awesome 5 Free", serif;
	font-weight: 600;
	transition: all 0s !important;
	border: none;
	vertical-align: baseline;
	width: auto;
	height: auto;
	line-height: 1;
	font-size: 5rem;
	color: black;
} */
.owl-carousel .next-slide {
	right: -33px;
	color: black;
}
.owl-carousel .next-slide .fa {
	font-size: 32px;
}
.owl-carousel .prev-slide:hover {
	background-position: 0px -53px;
}
.owl-carousel .next-slide:hover {
	background-position: -24px -53px;
}

.heading .letter {
	display: inline-block;
	/* margin: 0 0.2vw; */
	min-width: 10px; /* this is for spaces */
	font-family: "Raleway", sans-serif;
}
.testimonial-social-icons a i {
	font-size: 1.6rem;
}
.testimonial-social-icons .fa-linkedin-in {
	color: #0077b5;
}
/* case studies */
.case-item {
	position: relative;
	height: 500px;
}
.case-item img {
	position: absolute;
	height: 100% !important;
	width: 100%;
	top: 0;
	left: 0;
	object-fit: cover;
}
.case-item .bg-overlay {
	background: linear-gradient(0deg, #000, transparent);
}
.case-item .news-text-box {
	z-index: 3;
	position: absolute;
	bottom: 10px;
	color: white;
	padding: 20px 30px;
}
.case-item .news-text-box h6 {
	font-size: 2.3rem;
}
.case-item .news-text-box p {
	font-size: 1.3rem;
}
.case-item .news-text-box :where(a, p, i) {
	color: white;
}
.case-item a:hover {
	color: white !important;
}
.testimonial-person {
	background-color: #eae6e6 !important;
}
.testimonial-person :where(h4, p) {
	color: black !important;
}
.testimonial-person .post {
	width: 180px !important;
}
.testimonial-person .text-content {
	margin-top: 40px !important;
}
.linkedIn {
	width: 35px;
	height: 35px;
}

.react-tweet-theme p {
	font-size: 1rem;
	color: #000;
}
.home-services {
	padding-bottom: 300px;
	height: auto;
}
.home-services-img {
	background-image: url("../../Images/home-service-bg.jpg");
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	color: #ffffff;
	background-position: center center;
	position: relative;
	overflow: visible;
	padding-top: 5.5rem;
}
.home-services .cards {
	position: absolute;
	left: -15px;
	bottom: 0;
	width: 100%;
}
.home-services .cards .row {
	width: 100%;
}
.home-services .cards .card {
	background-color: #f8f8f8;
}
.home-services .cards .card .card-body {
	padding: 1.25rem 2rem;
}
.home-services .cards .card .logos {
	background-color: lightgray;
}
.home-services .cards .card h5 {
	color: black !important;
	font-size: 2rem;
}
.home-services .cards .card .it i {
	color: #9c27b0;
}
.home-services .cards .card p {
	color: #000;
}
.home-services .cards .card .it {
	display: flex;
	align-items: center;
	gap: 10px;
}
.home-services .cards .card .it a,
.home-services .cards .card .it a:hover {
	color: #000;
}
.home-services .cards .card .it img {
	width: 50px;
	height: 50px;
}
.our-Expertise {
	/* background-image: url("../../Images/home-service-bg.jpg"); */
	height: auto;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	color: #ffffff;
	background-position: center center;
}
.our-Expertise .prod-card {
	width: 100% !important;
	margin-bottom: 30px;
}
.our-Expertise .prod-card .low-code-img {
	width: 90px;
}
.our-Expertise .prod-card h5 {
	font-size: 1.15rem;
}
.our-Expertise .prod-card:hover {
	box-shadow: 1px 1px 10px #ccc;
}
.home-testimoniel .testimonial-person .post {
	height: 200px;
}
.home-testimoniel .testimonial-person .post > img {
	border-radius: 0 !important;
	height: 100%;
	aspect-ratio: 3/2;
}
@media screen and (min-width: 767px) and (max-width: 992px) {
	.home-services {
		padding-bottom: 750px;
	}
}
@media screen and (max-width: 767px) {
	.dropdown-menu .dropdown-title {
		display: none;
	}
	.navbar-nav .nav-link:focus,
	.dropdown-menu .dropdown-item:focus,
	.dropdown-menu.megamenu .dropdown-item:focus {
		color: #ffb902;
	}
	#values .left-circle,
	#values .right-circle {
		width: 300px;
		height: 300px;
		padding: 45px;
	}
	.circles {
		flex-direction: column;
	}
	#values .right-circle {
		right: 0;
		bottom: 50px;
	}
	.left-circle .about-p,
	.right-circle .about-p {
		line-height: 1;
		font-size: 0.7rem;
	}
	.tech-partners {
		height: auto;
		/* margin-top: 40px; */
	}
	.tech-partners .logos {
		max-width: 540px;
		gap: 2px;
	}
	.tech-partners .logos .aws {
		height: 20px !important;
	}
	.tech-partners .logos img {
		/* width: 50px !important; */
		height: 50px !important;
	}
	.slide3 .logos {
		gap: 0;
	}
	.slide3 .logos img {
		width: 100px !important;
		height: 80px !important;
		flex-basis: 0 0 25%;
	}
	.slide3 .logos .methodical img {
		width: 30px !important;
		height: 30px !important;
	}
	.slide3 .logos .methodical h4 {
		font-size: 0.8rem !important;
	}
	.talk-to-us {
		display: flex;
		/* flex-direction: column; */
		align-items: center;
		justify-content: center;
		/* gap: 10px; */
	}
	.talk-to-us a {
		height: 50px;
		padding: 13px 20px !important;
		font-size: 14px !important;
	}
	.tp-caption {
		line-height: 1.2 !important; /* Adjust the line height for mobile screens as desired */
	}
	.methodical img {
		width: 40px !important;
		height: 40px !important;
	}
	.case-item {
		flex-basis: 100%;
		margin-right: 0;
	}
	.home-services .cards {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
	}
	.home-services {
		padding-bottom: 1100px;
	}
	.home-services .cards .card {
		margin-bottom: 20px;
	}
	.our-Expertise {
		height: auto;
	}
	.our-Expertise .prod-card {
		width: 80% !important;
	}
}
@media screen and (min-width: 768px) {
	.tp-parallax-wrap {
		left: 20px !important;
	}
}
@media screen and (max-width: 360px) {
	.home-services {
		padding-bottom: 1200px;
	}
}
.active {
	color: #d91a60 !important;
}
.nav-bottom-line .navbar-nav .nav-link::after {
	background-color: transparent !important;
}
