/* ===================================
    About
====================================== */

/*
    Theme Name: MegaOne
    Theme URI:
    Author: Themes Industry
    Author URI:
    Description: One Page Parallax Template
    Tags: one page, multipurpose, parallax, creative, html5
*/

/* ===================================
    Table of Content
====================================== */

/*  - Fonts
    - General
    - Social Icon Hover
    - Header And Navigation
    - Slider
    - About
    - Work
    - Clients
    - Price
    - Blog
    - Contact
    - Footer
    - Loader
    - Media Queries   */

/* ===================================
    Google fonts
====================================== */
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500,700");

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");

.font-primary {
	font-family: "Raleway", sans-serif;
}
.font-secondary {
	font-family: "Raleway", sans-serif;
}

/* ===================================
    General
====================================== */
body {
	font-family: "Raleway", sans-serif;
	font-size: 15px;
	color: #666666;
	font-weight: 300;
	line-height: 26px;
	letter-spacing: 0.5px;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
}
ol,
ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
section,
.section-padding {
	position: relative;
	padding: 5.5rem 0;
	overflow: hidden;
}

.portfolio-padding {
	padding: 7.5rem 0 4rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Raleway", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1;
}
h1 {
	font-size: 72px;
}
.h1 {
	font-size: 4rem;
}
h2 {
	font-size: 3rem;
}
p {
	margin: 0;
	padding: 0;
	font-size: 15px;
	color: #535353;
}
.line-height-heading {
	line-height: 1.2;
}
.line-height-normal {
	line-height: normal;
}
.line-height-17px {
	line-height: 17px;
}
.line-height-25px {
	line-height: 25px;
}
.line-height-35px {
	line-height: 35px;
}
img {
	max-width: 100%;
}
/* Width */

.w-71 {
	width: 71% !important;
}
.w-73 {
	width: 73% !important;
}
.w-77 {
	width: 77% !important;
}
.w-82 {
	width: 82% !important;
}
.w-87 {
	width: 87% !important;
}
.w-93 {
	width: 93% !important;
}
.w-265 {
	max-width: 265px;
}
.va-middle {
	vertical-align: middle;
}

a,
a:active,
a:focus,
a:hover {
	text-decoration: none !important;
	outline: none;
}
a:focus,
a:hover {
	color: #1f1f1f;
}
a,
*::before,
*::after,
img,
span,
input,
button,
.navbar,
.fixedmenu,
.tp-bullet,
.owl-dot,
.owl-prev,
.owl-next {
	-webkit-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
i::before,
i::after {
	-webkit-transition: all 0s ease;
	-o-transition: all 0s ease;
	transition: all 0s ease;
}
/* transition none */
.no-transition * {
	transition-timing-function: initial;
	-moz-transition-timing-function: initial;
	-webkit-transition-timing-function: initial;
	-o-transition-timing-function: initial;
	-ms-transition-timing-function: initial;
	transition-duration: 0s;
	-moz-transition-duration: 0s;
	-webkit-transition-duration: 0s;
	-o-transition-duration: 0s;
	-ms-transition-duration: 0s;
}
.no-animation {
	animation: none !important;
}
.no-transform {
	transform: none !important;
}
::-webkit-scrollbar-track {
	background-color: #e9edf0;
	border-left: 1px solid #ededed;
}
::-webkit-scrollbar {
	width: 7px;
	background-color: #0284d0;
}
::-webkit-scrollbar-thumb {
	background: #0284d0;
}

.pt-10px {
	padding-top: 10px;
}
.pt-15px {
	padding-top: 15px;
}
.pt-20px {
	padding-top: 20px;
}
.pb-10px {
	padding-bottom: 10px;
}
.pb-40px {
	padding-bottom: 40px;
}
.pl-2rem {
	padding-left: 2rem;
}
.pt-7rem {
	padding-top: 7rem;
}
.pb-7rem {
	padding-bottom: 7rem;
}
.p-7rem {
	padding: 7rem 0;
}
.p-half {
	padding: 3.75rem 0;
}
.pt-half {
	padding-top: 3.75rem;
}
.pb-half {
	padding-bottom: 3.75rem;
}
.mt-7rem {
	margin-top: 7rem;
}
.mb-7rem {
	margin-bottom: 7rem;
}
.mt-10px {
	margin-top: 10px;
}
.mb-10px {
	margin-bottom: 10px;
}
.mt-15px {
	margin-top: 15px;
}
.mb-15px {
	margin-bottom: 15px;
}
.mt-20px {
	margin-top: 20px;
}
.mb-20px {
	margin-bottom: 20px;
}
.mt-25px {
	margin-top: 25px;
}
.mb-25px {
	margin-bottom: 25px;
}
.mt-30px {
	margin-top: 30px;
}
.mb-30px {
	margin-bottom: 30px;
}
.mt-35px {
	margin-top: 35px;
}
.mb-35px {
	margin-bottom: 35px;
}
.mt-40px {
	margin-top: 40px;
}
.mb-40px {
	margin-bottom: 40px;
}
.mt-50px {
	margin-top: 50px;
}
.mb-50px {
	margin-bottom: 50px;
}
.mt-80px {
	margin-top: 80px;
}
.mt-105px {
	margin-top: 105px;
}
.mt-120px {
	margin-top: 120px;
}
.mt-750rem {
	margin-top: 0.75rem !important;
}
.mt-130rem {
	margin-top: 1.3rem !important;
}
.mb-175rem {
	margin-bottom: 1.75rem !important;
}
.mb-2rem {
	margin-bottom: 2rem !important;
}
.heading-space {
	margin-bottom: 4.125rem;
}
.heading-space-half {
	margin-bottom: 2rem;
}
/* center block */
.center-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-moz-box-orient: vertical;
	-moz-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

#google-map {
	height: 400px;
	width: 100%;
}
.col-map #google-map {
	height: 485px;
}
.h-100vh {
	min-height: 100vh !important;
}
.hidden {
	visibility: hidden !important;
}

.heading-para-setting {
	max-width: 950px;
	margin: 0 auto;
}
.fullscreen {
	height: 100vh !important;
	width: 100%;
}
.half-section {
	width: 100%;
}
.half-section {
	position: relative;
	overflow: hidden;
}

.content-setting {
	text-transform: capitalize;
	font-weight: 300;
	max-width: 650px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 15px;
}

/* hover on images */
.hover-effect::before,
.hover-effect::after {
	content: "";
	background: #fff;
	height: 0;
	width: 0;
	z-index: 1;
	position: absolute;
	-webkit-transition-duration: 1.3s !important;
	-o-transition-duration: 1.3s;
	transition-duration: 1.3s !important;
}
.hover-effect::before {
	right: 0;
	opacity: 1;
	top: 0;
}
.hover-effect::after {
	bottom: 0;
	opacity: 0.7;
	left: 0;
}
.hover-effect:hover::after,
.hover-effect:hover::before {
	height: 100%;
	opacity: 0;
	width: 100%;
}

/* img wrap */
.image {
	overflow: hidden;
}
a.image {
	display: block;
}
.image img {
	width: 100%;
	height: 100%;
	display: block;
}
.image,
.image img {
	position: relative;
}
.split-blog-scale img {
	transform: scale(1);
	transition: 1s;
}
.split-blog-scale:hover img {
	transform: scale(1.2);
}
/* background color */
.bg-light-gray {
	background-color: #f8f8f8;
}
.bg-light-gray2 {
	background-color: #efefef;
}
.bg-light-gray3 {
	background-color: #f2f2f2;
}
.bg-blue {
	background-color: #0284d0;
}
.bg-green {
	background-color: #28a745;
}
.bg-black {
	background-color: #1f1f1f;
}
.bg-black2 {
	background-color: #262626;
}
.bg-black3 {
	background-color: #19283e;
}
.bg-white2 {
	background-color: #ffffff;
}
.transparent-bg {
	background-color: transparent;
}
.bg-dark {
	background-color: #1d1d21 !important;
}
/* background color hover */
.hvr-bg-light-gray:hover {
	background-color: #f6f6f6;
}
.hvr-bg-blue:hover {
	background-color: #0284d0;
}
.hvr-bg-black:hover {
	background-color: #1f1f1f;
}
.hvr-bg-black2:hover {
	background-color: #262626;
}
.hvr-bg-black3:hover {
	background-color: #19283e;
}
.hvr-bg-white2:hover {
	background-color: #ffffff;
}
/* overlay */
.overlay {
	background-color: #0284d0;
	opacity: 0.75;
	padding-bottom: 10px;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 10px;
	position: absolute;
	visibility: hidden;
	z-index: 98;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
}
/* overlay-setting */
.bg-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
/* opacity */
.opacity-1 {
	opacity: 0.1;
}
.opacity-2 {
	opacity: 0.2;
}
.opacity-3 {
	opacity: 0.3;
}
.opacity-4 {
	opacity: 0.4;
}
.opacity-5 {
	opacity: 0.5;
}
.opacity-6 {
	opacity: 0.6;
}
.opacity-7 {
	opacity: 0.7;
}
.opacity-8 {
	opacity: 0.8;
}
.opacity-9 {
	opacity: 0.9;
}

/* z-index */
.z-index-1 {
	z-index: 1;
}

/* text-color */
.color-black {
	color: #1f1f1f;
}
.color-grey {
	color: #535353;
}
.color-light-grey {
	color: #6f6f6f;
}
.color-light-grey2 {
	color: #a5a5a5;
}
.color-vlight-grey {
	color: #8c8c8c;
}
.color-vlight-grey2 {
	color: #ebebeb;
}
.color-white {
	color: #ffffff;
}
.color-light-gw {
	color: #f7f7f7;
}
.color-blue {
	color: #0284d0;
}
.color-green {
	color: #28a745;
}
.text-black {
	color: #1f1f1f !important;
}
.text-blue {
	color: #0284d0 !important;
}

/* font-size */
.font-13 {
	font-size: 13px;
}
.font-14 {
	font-size: 14px;
}
.font-16 {
	font-size: 16px;
}
.font-18 {
	font-size: 18px;
}
.font-20 {
	font-size: 20px;
}
.font-30 {
	font-size: 30px;
}
.font-72 {
	font-size: 72px;
}
.slider-text {
	font-size: 60px;
}

/* font-weight */
.font-weight-100 {
	font-weight: 100;
}
.font-weight-200 {
	font-weight: 200;
}
.font-weight-500 {
	font-weight: 500;
}
.font-weight-600 {
	font-weight: 600;
}

/* border */
.border-light2 {
	border: 1px solid #e8e8e8;
}
.border-vlight {
	border: 1px solid #f8f9fa;
}

/* buttons */
.btn:hover,
.btn:active,
.btn:focus {
	outline: none;
	box-shadow: none;
}
button {
	cursor: pointer;
}
button:hover,
button:active,
button:focus {
	outline: none;
}
.btn-setting {
	padding: 9px 34px;
	width: auto;
	display: inline-block;
	font-weight: 400;
	line-height: 25px;
	font-size: 15px;
	text-transform: capitalize;
	text-align: center;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	border: 1px solid transparent;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease !important;
}
nav .btn-setting {
	font-size: 14px;
	font-weight: 600;
	padding: 10px 20px;
	text-transform: uppercase;
}
.btn-scale {
	border: none;
}
.btn-scale:before {
	position: absolute;
	content: "";
	right: 0;
	left: -101%;
	width: 101%;
	height: 100%;
	background: #0284d0;
	z-index: -1;
	top: 0;
	-webkit-transition: all 0.7s;
	-o-transition: all 0.7s;
	-moz-transition: all 0.7s;
	transition: all 0.7s;
	overflow: hidden;
}
.btn-setting:hover.btn-scale:before,
.btn-setting:active.btn-scale:before,
.btn-setting:focus.btn-scale:before {
	top: 0;
	left: 0;
	-webkit-transition: all 0.7s;
	-o-transition: all 0.7s;
	-moz-transition: all 0.7s;
	transition: all 0.7s;
}
.btn-blue.btn-scale:before {
	background: #28a745;
}
.btn-green.btn-scale:before {
	background: #0284d0;
}
.btn-hvr-blue:hover,
.btn-hvr-blue:focus {
	border-color: #d91a60;
	background-color: #d91a60;
	color: #ffffff;
}
.btn-hvr-green:hover,
.btn-hvr-green:focus {
	border-color: #28a745;
	background-color: #28a745;
	color: #ffffff;
}
.btn-hvr-transparent-grey:hover,
.btn-hvr-transparent-grey:focus {
	border-color: #acacac;
	background-color: transparent;
	color: #1f1f1f;
}

.btn-blue {
	border-color: #0284d0;
	background-color: #0284d0;
	color: #ffffff;
}

.btn-green {
	border-color: #28a745;
	background-color: #28a745;
	color: #ffffff;
}

.btn-white {
	background-color: #ffffff;
	color: #1f1f1f;
}
.btn-transparent2 {
	border: 1px solid #1f1f1f;
	color: #1f1f1f;
}
.btn-transparent3 {
	color: #ffffff;
}
.rev_slider .btn-transparent3,
.rev_slider .btn-border {
	border: 2px solid;
}

.btn-black {
	border-color: #202020;
	background-color: #202020;
	color: #ffffff;
}

.btn-transparent {
	border: 1px solid #ffffff;
	background: transparent;
}
.btn-white:hover,
.btn-white:focus,
.btn-transparent2:hover,
.btn-transparent2:focus {
	color: #ffffff;
}
.btn-transparent3:hover,
.btn-transparent3:focus {
	color: #1f1f1f;
}
.btn-white2:hover,
.btn-white2:focus {
	color: #1f1f1f;
}

.btn-transparent-white {
	background: transparent;
	border: 1px solid !important;
	border-color: #ffffff;
}
/* border radius */
.radius-small {
	border-radius: 4px;
}
.radius-0 {
	border-radius: 0 !important;
}
.container.radius {
	border-radius: 36px;
}
.header-appear .container.radius {
	border-radius: 0;
}
/* owl carousel bullets */
.owl-dots .owl-dot {
	height: 11px;
	width: 11px;
	margin: 2px 10px;
	position: relative;
	display: inline-block;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background: #e5e5e5 !important;
	cursor: pointer;
}
.owl-dots .owl-dot.active span,
.owl-dots .owl-dot:hover span {
	background: #e5e5e5;
}
.owl-dots .owl-dot.active {
	background: #d91a60 !important;
}
.team-classic.owl-team .item {
	margin-bottom: 40px;
}

/* ===================================
   Social Icon Hover
====================================== */
/*icon bg hover */
.facebook-bg-hvr:hover {
	background: #4267b2 !important;
	color: #fff !important;
	border: 1px solid transparent !important;
}
.twitter-bg-hvr:hover {
	background: #1da1f2 !important;
	color: #fff !important;
	border: 1px solid transparent !important;
}
.instagram-bg-hvr:hover {
	background: #c32aa3 !important;
	color: #fff !important;
	border: 1px solid transparent !important;
}
.linkedin-bg-hvr:hover {
	background: #0077b5 !important;
	color: #fff !important;
	border: 1px solid transparent !important;
}
.pinterest-bg-hvr:hover {
	background: #bd081c !important;
	color: #fff !important;
	border: 1px solid transparent !important;
}
.google-bg-hvr:hover {
	background: #db4437 !important;
	color: #fff !important;
	border: 1px solid transparent !important;
}

/*icon text hover */
.facebook-text-hvr:hover {
	color: #4267b2 !important;
}
.twitter-text-hvr:hover {
	color: #1da1f2 !important;
}
.instagram-text-hvr:hover {
	color: #c32aa3 !important;
}
.linkedin-text-hvr:hover {
	color: #0077b5 !important;
}
.pinterest-text-hvr:hover {
	color: #bd081c !important;
}
.google-text-hvr:hover {
	color: #db4437 !important;
}

/* ===================================
    Header & Navigation
====================================== */

/* menu bars */
.menu-lines {
	transform: rotateY(180deg);
}
.menu-bars-setting {
	position: absolute;
	right: 30px;
}
.menu-inner {
	position: relative;
	right: 0;
	margin-left: 1rem;
}
.menu_bars,
.menu_bars2 {
	padding: 5px;
	width: 38px;
	cursor: pointer;
	z-index: 1;
}
.menu_bars2 {
	height: 36px;
	position: absolute;
}
.menu_bars span,
.menu_bars2 span {
	background: #1f1f1f;
	width: 30px;
	height: 2px;
	display: block;
}
.transparent-bg .menu_bars span,
.menu-outer.menu_bars span {
	background: #ffffff;
}
.transparent-bg.black .menu_bars span,
.just-menu .menu_bars span {
	background: #1f1f1f;
}
.header-appear .menu_bars span,
.header-appear .menu-outer.menu_bars span,
.header-appear .just-menu .menu_bars span,
.just-menu.black .menu_bars span {
	background: #1f1f1f;
}
.menu_bars,
.menu_bars span,
.menu_bars2,
.menu_bars2 span {
	-webkit-transition: 300ms linear all;
	-o-transition: 300ms linear all;
	transition: 300ms linear all;
}

.menu_bars span:nth-child(1) {
	width: 25px;
}
.menu_bars span:nth-child(2) {
	width: 15px;
	margin: 5px 0;
}
.menu_bars span:nth-child(3) {
	width: 20px;
}
.menu_bars:hover span {
	width: 30px;
}
.menu_bars {
	background: none;
	box-shadow: none;
	border: none;
}
.menu_bars2 span:nth-child(2) {
	display: none;
}
.menu_bars2 span:nth-child(1),
.menu_bars2 span:nth-child(3) {
	width: 30px;
	top: 20px;
	position: absolute;
	left: 5px;
	margin-top: -1px;
}
.menu_bars2 span:nth-child(1) {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}
.menu_bars2 span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
/* logo */
.logo {
	max-width: 140px;
}
.logo img {
	vertical-align: middle;
}
.header-appear .navbar .logo-default,
.navbar .logo-scrolled {
	display: none;
}
.header-appear .navbar .logo-scrolled,
.navbar .logo-default {
	display: inline-block;
}

/* nav top default */
.navbar-top-default {
	position: absolute !important;
	top: 0;
	z-index: 99;
	height: 90px;
	width: 100%;
	-webkit-transition: all 0.1s ease;
	-moz-transition: all 0.1s ease;
	-ms-transition: all 0.1s ease;
	-o-transition: all 0.1s ease;
	transition: all 0.1s ease;
}
.nav-height-small {
	height: 64px;
}

.navbar-top-default .navbar-nav .nav-link {
	font-family: "Raleway", sans-serif;
	font-size: 14px;
	font-weight: 600;
	color: #ffffff;
	padding: 0.25rem 0 0;
	margin-right: 1rem;
	margin-left: 1rem;
	text-transform: uppercase;
}
.header-appear .navbar-nav .nav-link:hover,
.header-appear .navbar-nav .nav-link.active {
	color: #ffffff;
}
/* Nav Bottom Line Hover Effect */
.nav-bottom-line .navbar-nav .nav-link::after {
	content: "";
	display: block;
	width: 0;
	height: 5px;
	background: #0284d0;
	transition: width 0.3s;
	border-radius: 50px;
	transform-origin: right;
}
.nav-bottom-line .navbar-nav .nav-link.active::after {
	width: 100%;
}

.header-appear .transparent-bg .navbar-nav .nav-link,
.header-appear .center-logo .navbar-nav .nav-link,
.transparent-bg.black .navbar-nav .nav-link,
.center-logo.black .navbar-nav .nav-link,
.bg-white2.black .navbar-nav .nav-link,
.header-appear .bg-white2.black .navbar-nav .nav-link {
	color: #1f1f1f;
}

.header-appear .navbar-top-default {
	height: 75px;
	position: fixed !important;
	background: #ffffff;
	-webkit-animation-name: animationFade;
	-o-animation-name: animationFade;
	animation-name: animationFade;
	-webkit-animation-duration: 1s;
	-o-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	-o-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
	box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
}
/* fixed nav hover */
.header-appear .navbar-top-default .navbar-nav .nav-link:hover {
	color: #d91a60;
}

@-webkit-keyframes animationFade {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}
@-o-keyframes animationFade {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		-o-transform: none;
		transform: none;
	}
}
@keyframes animationFade {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		-o-transform: none;
		transform: none;
	}
}
/* nav social icons */
header .navbar-top-default .header-social {
	padding-left: 1rem;
}
header .navbar-top-default.seven-links .header-social {
	padding-left: 1rem;
	padding-right: 1rem;
}
header .navbar-top-default .header-social a {
	font-size: 14px;
	color: #1f1f1f;
	height: 36px;
	width: 37px;
	line-height: 38px;
	display: inline-block;
	text-align: center;
	margin: 0;
	border: 1px solid transparent;
	border-radius: 50%;
}
header .navbar-top-default .header-social a:hover {
	background: #ffffff;
}
header .transparent-bg.navbar-top-default .header-social a {
	color: #ffffff;
}
header .transparent-bg.black.navbar-top-default .header-social a {
	color: #1f1f1f;
}
header.header-appear .navbar-top-default .header-social a,
header.header-appear .transparent-bg.navbar-top-default .header-social a {
	color: #1f1f1f;
}
header.header-appear
	.navbar-top-default
	.header-social
	a.facebook-text-hvr:hover {
	background: #4267b2;
	color: #ffffff !important;
}
header.header-appear
	.navbar-top-default
	.header-social
	a.twitter-text-hvr:hover {
	background: #1da1f2;
	color: #ffffff !important;
}
header.header-appear
	.navbar-top-default
	.header-social
	a.linkedin-text-hvr:hover {
	background: #0077b5;
	color: #ffffff !important;
}
header.header-appear
	.navbar-top-default
	.header-social
	a.google-text-hvr:hover {
	background: #db4437;
	color: #ffffff !important;
}

header.header-appear .navbar-top-default .header-social a:hover {
	background: transparent;
}
/* nav box shadow */
.nav-box-shadow {
	-webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.15);
	box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.15);
}

/* ===================================
    Side Menu
====================================== */

.side-menu {
	width: 35%;
	position: fixed;
	right: 0;
	top: 0;
	background: #0284d0;
	z-index: 1032;
	height: 100%;
	-webkit-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0);
	-webkit-transition: -webkit-transform 0.5s ease;
	transition: -webkit-transform 0.5s ease;
	-o-transition: -o-transform 0.5s ease;
	transition: transform 0.5s ease;
	overflow: hidden;
}
.side-menu.left {
	left: 0;
	right: auto;
	-webkit-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0);
}
.side-menu.before-side {
	width: 280px;
}
.side-menu.side-menu-active,
.side-menu.before-side {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
.pul-menu .side-menu.side-menu-active {
	visibility: visible;
	opacity: 1;
}
.side-menu .navbar-brand {
	margin: 0 0 2.5rem 0;
}

/*Side overlay*/
#close_side_menu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	-webkit-transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
	-o-transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
	transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
	display: none;
	z-index: 1031;
	opacity: 0.4;
}

/*side clode btn*/
.side-menu .btn-close {
	height: 33px;
	width: 33px;
	display: inline-block;
	text-align: center;
	position: absolute;
	top: 30px;
	right: 15px;
	cursor: pointer;
}
.side-menu.before-side .btn-close {
	display: none;
}
.side-menu .btn-close::before,
.side-menu .btn-close::after {
	position: absolute;
	left: 16px;
	content: " ";
	height: 24px;
	width: 2px;
	background: #fff;
	top: 5px;
}
.side-menu .btn-close:before {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}
.side-menu .btn-close:after {
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

/*side open btn*/

.sidemenu_btn.left {
	left: 15px;
	right: auto;
}
.sidemenu_btn span {
	height: 2px;
	width: 100%;
	background: #ffffff;
	display: block;
}

.sidemenu_btn span:nth-child(2) {
	margin: 4px 0;
}

.side-menu .inner-wrapper {
	padding: 3.5rem 5rem;
	height: 100%;
	position: relative;
	overflow-y: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: left;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.pul-menu.pushwrap .side-menu .inner-wrapper {
	padding: 3.5rem 2.5rem;
}
.side-menu .side-nav {
	margin-bottom: 20px;
	display: block;
}
.side-nav .navbar-nav .nav-link {
	-webkit-transition: all 0.8s ease 500ms;
	-o-transition: all 0.8s ease 500ms;
	transition: all 0.8s ease 500ms;
}
.side-menu.side-menu-active .side-nav .navbar-nav .nav-link {
	opacity: 1;
}
.side-nav .navbar-nav .nav-link {
	display: inline-table;
	color: #fff;
	padding: 10px 0 10px 0 !important;
	font-size: 20px;
	font-weight: 500;
	line-height: normal;
	min-height: 50px;
	position: relative;
	text-transform: uppercase;
	-webkit-border-radius: 0;
	border-radius: 0;
}

.side-nav .navbar-nav .nav-link.active {
	background: transparent;
}
.side-menu p {
	font-size: 13px;
	margin-top: 0.5rem;
	margin-bottom: 0;
}

.social-icons-simple {
	margin-bottom: 0;
	margin-left: -10px;
}

.social-icons-simple li {
	display: inline-block;
}

.social-icons-simple li a {
	display: block;
	font-size: 16px;
	height: 36px;
	line-height: 38px;
	border: 1px solid transparent;
	text-align: center;
	width: 36px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	position: relative;
	z-index: 1;
	overflow: hidden;
	padding: 0;
	-webkit-transition: background-color 0.5s ease;
	-o-transition: background-color 0.5s ease;
	transition: background-color 0.5s ease;
}
.social-icons-simple li a:hover {
	background-color: transparent;
}

.social-icons-simple li.animated-wrap a:hover {
	background-color: transparent;
	color: #ffffff;
}

/* Side Nav Arrow */

.nav-icon .navbar-nav {
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	list-style: none;
}

.menu-line {
	position: absolute;
	left: -20px;
	font-size: 11px;
	color: #ffffff !important;
	top: 13px;
	display: block;
	text-align: center;
	width: 20px;
	height: 50px;
	pointer-events: none;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
	-webkit-transition-timing-function: cubic-bezier(1, 0.01, 0, 1);
	-webkit-transition-timing-function: cubic-bezier(1, 0.01, 0, 1.22);
	transition-timing-function: cubic-bezier(1, 0.01, 0, 1.22);
}

.nav-icon .navbar-nav .nav-link:nth-child(1).active ~ .menu-line {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.nav-icon .navbar-nav .nav-link:nth-child(2).active ~ .menu-line {
	-webkit-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0);
}

.nav-icon .navbar-nav .nav-link:nth-child(3).active ~ .menu-line {
	-webkit-transform: translate3d(0, 200%, 0);
	transform: translate3d(0, 200%, 0);
}

.nav-icon .navbar-nav .nav-link:nth-child(4).active ~ .menu-line {
	-webkit-transform: translate3d(0, 300%, 0);
	transform: translate3d(0, 300%, 0);
}

.nav-icon .navbar-nav .nav-link:nth-child(5).active ~ .menu-line {
	-webkit-transform: translate3d(0, 400%, 0);
	transform: translate3d(0, 400%, 0);
}

.nav-icon .navbar-nav .nav-link:nth-child(6).active ~ .menu-line {
	-webkit-transform: translate3d(0, 500%, 0);
	transform: translate3d(0, 500%, 0);
}

.nav-icon .navbar-nav .nav-link:nth-child(7).active ~ .menu-line {
	-webkit-transform: translate3d(0, 600%, 0);
	transform: translate3d(0, 600%, 0);
}

.nav-icon .navbar-nav .nav-link:nth-child(8).active ~ .menu-line {
	-webkit-transform: translate3d(0, 700%, 0);
	transform: translate3d(0, 700%, 0);
}

.nav-icon .navbar-nav .nav-link:nth-child(9).active ~ .menu-line {
	-webkit-transform: translate3d(0, 800%, 0);
	transform: translate3d(0, 800%, 0);
}
.nav-icon .navbar-nav .nav-link:nth-child(10).active ~ .menu-line {
	-webkit-transform: translate3d(0, 900%, 0);
	transform: translate3d(0, 900%, 0);
}

.nav-icon .navbar-nav .nav-link:nth-child(1):hover ~ .menu-line {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.nav-icon .navbar-nav .nav-link:nth-child(2):hover ~ .menu-line {
	-webkit-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0);
}

.nav-icon .navbar-nav .nav-link:nth-child(3):hover ~ .menu-line {
	-webkit-transform: translate3d(0, 200%, 0);
	transform: translate3d(0, 200%, 0);
}

.nav-icon .navbar-nav .nav-link:nth-child(4):hover ~ .menu-line {
	-webkit-transform: translate3d(0, 300%, 0);
	transform: translate3d(0, 300%, 0);
}

.nav-icon .navbar-nav .nav-link:nth-child(5):hover ~ .menu-line {
	-webkit-transform: translate3d(0, 400%, 0);
	transform: translate3d(0, 400%, 0);
}

.nav-icon .navbar-nav .nav-link:nth-child(6):hover ~ .menu-line {
	-webkit-transform: translate3d(0, 500%, 0);
	transform: translate3d(0, 500%, 0);
}

.nav-icon .navbar-nav .nav-link:nth-child(7):hover ~ .menu-line {
	-webkit-transform: translate3d(0, 600%, 0);
	transform: translate3d(0, 600%, 0);
}

.nav-icon .navbar-nav .nav-link:nth-child(8):hover ~ .menu-line {
	-webkit-transform: translate3d(0, 700%, 0);
	transform: translate3d(0, 700%, 0);
}

.nav-icon .navbar-nav .nav-link:nth-child(9):hover ~ .menu-line {
	-webkit-transform: translate3d(0, 800%, 0);
	transform: translate3d(0, 800%, 0);
}

.nav-icon .navbar-nav .nav-link:nth-child(10):hover ~ .menu-line {
	-webkit-transform: translate3d(0, 900%, 0);
	transform: translate3d(0, 900%, 0);
}

/* ===================================
   Slider
====================================== */
.rs-beforeafter-bounce-arrows
	.rs-main-slider-four-rs-964-rs-beforeafter-bounce-left {
	-webkit-animation: main-slider-four-rs-964-rs-beforeafter-bounce-left 1.5s
		ease-in-out infinite;
	animation: main-slider-four-rs-964-rs-beforeafter-bounce-left 1.5s ease-in-out
		infinite;
}

.banner-overlay::after {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}
.banner-overlay::after {
	background: rgba(0, 0, 0, 0.39);
}
.awaza.tp-bullets {
	z-index: 98;
}
.awaza.tp-bullets {
	left: -25px !important;
}
.awaza .tp-bullet.selected,
.awaza .tp-bullet:hover {
	background: transparent;
}
#banner-main .tp-bullet,
.bullet-bar .tp-bullet {
	border-radius: 36px;
}
#banner-main .tp-bullet:hover {
	background: #ffffff;
}
/* Revolution Slider Numbers Bullets */
.awaza .tp-bullet.selected .animated-wrap,
.awaza .tp-bullet.selected .animated-element {
	transform: none !important;
}
.awaza .animated-wrap {
	width: 25px;
}
.awaza .tp-bullet {
	width: unset;
	height: 6px;
	position: relative;
	background: transparent;
	cursor: pointer;
	color: #1f1f1f;
}
.awaza .tp-bullet-number {
	position: relative;
	text-align: left;
}
.awaza .tp-bullet.selected:nth-child(4) {
	padding-bottom: 4px;
	border-bottom: 2px solid;
}
.awaza,
.tp-tabs,
.tp-thumbs {
	top: 43% !important;
}
.awaza .tp-bullet .tp-count {
	font-family: "Raleway", sans-serif;
	font-size: 14px;
	position: relative;
}
.awaza .tp-count:after {
	content: "";
	border-radius: 36px;
	height: 5px;
	width: 27px;
	display: block;
	position: absolute;
	background: #ffffff;
	top: 100%;
	visibility: visible;
	opacity: 1;
}
.awaza .tp-count:before {
	content: "";
	border-radius: 50%;
	height: 5px;
	width: 5px;
	display: block;
	position: absolute;
	background: #ffffff;
	transform: translateX(-250%);
}
.awaza .tp-bullet.selected .tp-count:before,
.awaza .tp-bullet.selected .tp-count:after {
	background: #28a745;
}

.slide-action {
	font-size: 14px;
	vertical-align: middle;
	display: inline-block;
	padding: 10px 0 !important;
	text-transform: capitalize;
}
.slide-action > a {
	display: inline-block;
	letter-spacing: 2px;
}
.slide-action h2 {
	display: inline-block;
	vertical-align: bottom;
	font-size: 3.75rem;
}
.slide-action span {
	display: inline-block;
	height: 50px;
	width: 50px;
	border-radius: 50%;
	color: #ffffff;
	text-align: center !important;
	position: relative;
	font-size: 12px;
}
.slide-action span i {
	line-height: 50px;
	font-size: 10px;
	margin-left: 2px;
}
.slide-action span::before {
	content: "";
	border-radius: 50%;
	border: 3px solid;
	border-color: #ffffff;
	position: absolute;
	top: 5px;
	right: 5px;
	left: 5px;
	bottom: 5px;
	transition: 0.1s;
}
.slide-action span:hover::before {
	opacity: 0;
	-webkit-transform: scale(1.2, 1.2);
	-ms-transform: scale(1.2, 1.2);
	-o-transform: scale(1.2, 1.2);
	transform: scale(1.2, 1.2);
}
.slide-action.play span:hover {
	background: #ffffff;
	-webkit-animation: pulse_effect2 1s infinite;
	-o-animation: pulse_effect2 1s infinite;
	animation: pulse_effect2 1s infinite;
}
.slide-action.play span:hover i {
	color: #0284d0;
}
@-webkit-keyframes pulse_effect2 {
	0% {
		-webkit-box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.9);
		box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.9);
	}
	25% {
		-webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.6);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.6);
	}
	70% {
		-webkit-box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.3);
		box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.3);
	}
	100% {
		-webkit-box-shadow: 0 0 0 60px rgba(255, 255, 255, 0.1);
		box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1);
	}
}
@-o-keyframes pulse_effect2 {
	0% {
		box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.9);
	}
	25% {
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.6);
	}
	70% {
		box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.3);
	}
	100% {
		box-shadow: 0 0 0 20px rgba(100, 48, 148, 0.1);
	}
}
@keyframes pulse_effect2 {
	0% {
		-webkit-box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.9);
		box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.9);
	}
	25% {
		-webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.6);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.6);
	}
	70% {
		-webkit-box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.3);
		box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.3);
	}
	100% {
		-webkit-box-shadow: 0 0 0 60px rgba(255, 255, 255, 0.1);
		box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1);
	}
}
.blue-box {
	opacity: 0.7 !important;
}
/* ===================================
   About
====================================== */
.main-title {
	position: relative;
	margin-bottom: 4rem;
	text-align: center;
}
.main-title h5 {
	font-family: "Raleway", sans-serif;
	font-weight: 400;
	margin-bottom: 1.1rem;
	color: #1f1f1f;
}
.main-title h2 {
	text-transform: capitalize;
	font-weight: 300;
	color: #1f1f1f;
	line-height: 1.2;
	/* max-width: 650px; */
	margin-bottom: 1.5rem;
	/* margin-left: auto;
    margin-right: auto; */
}
.main-title p {
	/* max-width: 730px; */
	margin: 0;
	line-height: 1.5;
}
.main-title .heading {
	font-weight: 500 !important;
}
.main-title.style-two h5 {
	margin-bottom: 1.5rem;
}
.main-title.style-two h2 {
	text-transform: initial;
	font-size: 42px;
	font-weight: 500;
	/* max-width: 720px; */
}
.main-title.style-two p {
	color: #1f1f1f;
	/* max-width: 720px; */
}

.about-box {
	border-radius: 5px;
	padding: 40px 10px;
	background: #ffffff;
	text-align: center;
	cursor: pointer;
	-webkit-box-shadow: 0 0 5px rgba(127, 123, 123, 0.1);
	box-shadow: 0 0 5px rgba(127, 123, 123, 0.1);
	transition: ease-in-out 0.3s;
	z-index: 3;
	width: calc(33.33% - 20px);
	border: 1px solid lightgray;
	position: relative;
}
.about-box:hover {
	background: #d10d55;
	-webkit-box-shadow: 0 0 10px -2px rgba(127, 123, 123, 0.9);
	box-shadow: 0 0 10px -2px rgba(127, 123, 123, 0.9);
}
.about-box .about-main-icon i {
	color: #0284d0;
	font-size: 30px;
	transform: translateY(0);
	transition: ease-in-out 0.3s;
}
.about-box:hover .about-main-icon i {
	transform: translateY(-10px);
}
.about-box h5 {
	font-weight: 600;
	color: #1f1f1f;
	transition: ease-in-out 0.3s;
	font-size: 1rem;
}
.about-box:hover i,
.about-box:hover h5 {
	color: #fff;
	transition: ease-in-out 0.3s;
}
.about-opacity-icon {
	width: 100%;
	display: flex;
	justify-content: center;
}
.about-opacity-icon i {
	position: absolute;
	color: #28a745;
	top: 30px;
	font-size: 100px;
	font-weight: 800;
	line-height: 1;
	text-transform: capitalize;
	opacity: 0;
	animation: scale 0.6s alternate infinite linear both;
}
@keyframes scale {
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(-10px);
	}
}
.about-box:hover .about-opacity-icon i {
	opacity: 0.09;
}

.about-box.active {
	background: #d10d55;
	-webkit-box-shadow: 0 0 10px -2px rgba(127, 123, 123, 0.9);
	box-shadow: 0 0 10px -2px rgba(127, 123, 123, 0.9);
}
.about-box.active i,
.about-box.active h5 {
	color: #fff;
}
.about-box.active .about-opacity-icon i {
	opacity: 0.09;
}
.about-box.active:hover {
	background: #fff;
	-webkit-box-shadow: 0 0 5px rgba(127, 123, 123, 0.1);
	box-shadow: 0 0 5px rgba(127, 123, 123, 0.1);
}
.about-box.active:hover i {
	color: #0284d0;
}
.about-box.active:hover h5 {
	color: #1f1f1f;
}
.about-box.active:hover .about-opacity-icon i {
	opacity: 0.09;
}

.about-box.active.about-box-green {
	background: #fff;
	-webkit-box-shadow: 0 0 5px rgba(127, 123, 123, 0.1);
	box-shadow: 0 0 5px rgba(127, 123, 123, 0.1);
}
.about-box.active.about-box-green i {
	color: #d91a60;
}
.about-box.active.about-box-green h5 {
	color: #1f1f1f;
}
.about-box.active.about-box-green .about-opacity-icon i {
	opacity: 0;
}
.about-box.active.about-box-green:hover {
	background: #d10d55;
}
.about-box.active.about-box-green:hover i {
	color: #ffffff;
}
.about-box.active.about-box-green:hover h5 {
	color: #ffffff;
}
.about-box.active.about-box-green:hover .about-opacity-icon i {
	opacity: 0.09;
}

/* ===================================
   Stats
====================================== */

/* about progressbar skills */
.split-container-setting {
	max-width: 460px;
	margin-left: auto;
	margin-right: 96px;
}
.split-container-setting .main-title h2 {
	max-width: 100%;
	text-transform: initial;
}
.split-container-setting .main-title p {
	max-width: 100%;
	margin: 0;
}
.progress {
	height: 0.5rem;
	margin-top: 1rem;
}
.custom-progress {
	text-transform: capitalize;
	color: #1f1f1f;
	margin-bottom: 2rem;
}

.bg-change .split-container-setting .main-title h5,
.bg-change .split-container-setting .main-title h2,
.bg-change .split-container-setting h6 {
	color: #ffffff;
}
.bg-change.bg-blue .progress {
	background: #329ad7;
}

.progress-bar {
	border-radius: 50px;
}

.split-container-setting.style-two {
	max-width: 460px;
	margin-left: 100px;
	margin-right: auto;
}
.split-container-setting.style-three {
	max-width: 600px;
	margin: 0 auto;
}
/* ===================================
   Team
====================================== */

.team-classic .item {
	text-align: center;
}
.team-classic .item img {
	transform: scale(1);
	transition: 1s;
}
.team-classic .item:hover img {
	transform: scale(1.2);
	transition: 1s;
}
.team-data-img {
	overflow: inherit;
}
.team-classic-content {
	padding: 20px 0 15px;
	background: #ffffff;
}

.team-classic .team-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	color: #ffffff;
	opacity: 0;
	z-index: 2;
	transition: 2s;
}
.team-classic .team-overlay:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000000;
	opacity: 0.7;
	color: white;
}
.team-classic .item:hover .team-overlay {
	opacity: 0.8;
	visibility: visible;
	transition: 0.5s;
	background-color: rgba(0, 0, 0);
	color: white;
}

.team-classic .item:hover ul li {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
	transition: 0.5s ease-in-out;
}
.team-classic .item:hover .team-classic-content h5 {
	color: #d91a60;
}
.team-classic .item .team-classic-content:after {
	position: relative;
	content: "";
	width: 100%;
	max-width: 80%;
	margin: 0 auto;
	display: table;
	height: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	bottom: -30px;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear;
	-webkit-transform: scaleX(0);
	-ms-transform: scaleX(0);
	transform: scaleX(0);
	background: #d91a60;
	visibility: hidden;
	opacity: 0;
}
.team-classic .item:hover .team-classic-content:after {
	-webkit-transform: scaleX(1);
	transform: scaleX(1);
	visibility: visible;
	opacity: 1;
}
/* ---- Social Icons ---- */
ul.social_icon li a {
	color: #fff;
	display: block;
	font-size: 16px;
	height: 36px;
	line-height: 35px;
	text-align: center;
	width: 36px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	position: relative;
	z-index: 1;
	overflow: hidden;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
ul.social_icon li a:hover,
ul.social_icon li a:focus {
	background: #b2cb20;
	border-color: transparent;
	color: #fff;
}
ul.social_icon li a.facebook:hover,
ul.social_icon li a.facebook:focus {
	background: #3b5999;
	border-color: #3b5999;
}
ul.social_icon li a.twitter:hover,
ul.social_icon li a.twitter:focus {
	background: #55acee;
	border-color: #55acee;
}
ul.social_icon li a.insta:hover,
ul.social_icon li a.insta:focus {
	background: #e4405f;
	border-color: #e4405f;
}
ul.social_icon li a.google:hover,
ul.social_icon li a.google:focus {
	background: #dd4b39;
	border-color: #dd4b39;
}

.team-classic ul li {
	opacity: 0;
	visibility: hidden;
	-webkit-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	-o-transform: translateY(-100%);
	transform: translateY(-540%);
	transition: 0.5s ease-in-out;
}

.team-classic ul li:first-child,
.team-classic .item:hover ul li:first-child {
	transition-delay: 0.2s;
}
.team-classic ul li:nth-child(2),
.team-classic .item:hover ul li:nth-child(2) {
	transition-delay: 0.3s;
}
.team-classic ul li:nth-child(3),
.team-classic .item:hover ul li:nth-child(3) {
	transition-delay: 0.4s;
}
.team-classic ul li:nth-child(4),
.team-classic .item:hover ul li:nth-child(4) {
	transition-delay: 0.5s;
}
.team-classic ul li:nth-child(5),
.team-classic .item:hover ul li:nth-child(5) {
	transition-delay: 0.6s;
}
.team-classic ul li:nth-child(6),
.team-classic .item:hover ul li:nth-child(6) {
	transition-delay: 0.7s;
}
.team-classic ul li:nth-child(7),
.team-classic .item:hover ul li:nth-child(7) {
	transition-delay: 0.8s;
}

/* ---- Social Icons ends ---- */

/* ===================================
   Cases
====================================== */
.cbp-caption-zoom .cbp-caption-activeWrap {
	opacity: 0;
	top: 0;
	background-color: rgb(49, 55, 66);
	-webkit-transition: opacity 0.4s;
	transition: opacity 0.4s;
}
.cbp-caption-zoom .cbp-caption-activeWrap:before {
	right: 0;
}
.cbp-caption-zoom .cbp-caption-activeWrap:after,
.cbp-caption-zoom .cbp-caption-activeWrap:before {
	opacity: 0.6;
	position: absolute;
	top: 0;
	content: "";
	width: 0;
	height: 100%;
	background-color: rgb(49, 55, 66);
	-webkit-transition: all 0.45s ease-in-out 0s;
	transition: all 0.45s ease-in-out 0s;
	z-index: -1;
	-webkit-transform: skewX(-25deg);
	transform: skewX(-25deg);
}
.cbp-caption-zoom .cbp-caption-activeWrap:after {
	left: 0;
}
.cbp-caption-zoom .cbp-caption:hover .cbp-caption-activeWrap:after,
.cbp-caption-zoom .cbp-caption:hover .cbp-caption-activeWrap:before {
	opacity: 0.6;
	position: absolute;
	top: 0;
	content: "";
	width: 0;
	height: 100%;
	background-color: rgb(49, 55, 66);
	-webkit-transition: all 0.45s ease-in-out 0s;
	transition: all 0.45s ease-in-out 0s;
	z-index: -1;
	-webkit-transform: skewX(-25deg);
	transform: skewX(-25deg);
}
.cbp-caption-zoom .cbp-caption:hover .cbp-caption-activeWrap:after,
.cbp-caption-zoom .cbp-caption:hover .cbp-caption-activeWrap::before {
	width: 100%;
	transform: skewX(0);
	-webkit-transform: skewX(0);
	-moz-transform: skewX(0);
	-ms-transform: skewX(0);
	-o-transform: skewX(0);
}
.cbp-caption-zoom .cbp-caption:hover .cbp-caption-activeWrap {
	opacity: 1;
}
.cases-image-inner {
	position: relative;
	padding: 25px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
}
.cases-line {
	position: absolute;
	background: #ffffff;
}
.top {
	top: 0;
	right: 20px;
	width: 100px;
	height: 3px;
}
.top-right {
	top: 0;
	right: 20px;
	height: 100px;
	width: 3px;
}
.bottom {
	top: unset;
	bottom: 0;
	left: 20px;
	width: 100px;
	height: 3px;
}
.bottom-left {
	top: unset;
	bottom: 0;
	left: 20px;
	height: 100px;
	width: 3px;
}

.cbp-popup-singlePage .cbp-popup-content {
	max-width: 1020px;
}
.cbp-popup-singlePage {
	background: none;
}
.cbp-popup-singlePage:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.cbp-popup-singlePage:before {
	background: #ffffff;
	opacity: 1;
}
.cbp-popup-singlePage .cbp-popup-navigation-wrap {
	background: #28a745;
}
.cbp-popup-singlePage .cbp-popup-navigation-wrap:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #ffffff;
	opacity: 1;
}
.cbp-l-project-title {
	color: #1f1f1f;
	font: 300 3rem/1.2 "Raleway", sans-serif;
	letter-spacing: 1px;
	margin-bottom: 1.5rem;
	text-transform: capitalize;
	text-align: center;
}
.cbp-l-project-subtitle {
	color: #1f1f1f;
	font: 400 1.25rem/26px "Raleway", sans-serif;
	margin: 0 auto 50px;
	max-width: 500px;
	text-align: center;
}
.cbp-l-project-desc-text {
	font: 400 15px/26px "Raleway", sans-serif;
	color: #1f1f1f;
	margin-bottom: 20px;
}
.cbp-l-project-desc-title {
	border-bottom: none;
	margin-bottom: 22px;
	color: #1f1f1f;
}
.cbp-l-project-related-title {
	font: 400 16px/18px "Raleway", sans-serif;
	color: #1f1f1f;
	margin-top: 20px;
}
.cbp-l-project-desc-title span,
.cbp-l-project-details-title span {
	border-bottom: 1px solid #1f1f1f;
	display: inline-block;
	margin: 0 0 -1px 0;
	font: 400 16px/36px "Raleway", sans-serif;
	padding: 0 5px 0 0;
}
.cbp-l-project-details-list > li,
.cbp-l-project-details-list > div {
	border-bottom: 1px dashed #acacac;
	padding: inherit;
	color: #1f1f1f;
	font: 400 15px/30px "Raleway", sans-serif;
}
.cbp-l-project-details-list strong {
	color: #1f1f1f;
}

.cbp-popup-singlePage .cbp-popup-close {
	border-radius: 50%;
	transition: 0.5s;
}
.cbp-popup-singlePage .cbp-popup-next {
	border-radius: 50%;
	transition: 0.5s;
}
.cbp-popup-singlePage .cbp-popup-prev {
	border-radius: 50%;
	transition: 0.5s;
}

.cbp-popup-singlePage .cbp-popup-close:hover {
	background-position: -92px -182px;
	transition: 0.5s;
	background-color: #ffffff;
}
.cbp-popup-singlePage .cbp-popup-next:hover {
	background-position: -46px -182px;
	transition: 0.5s;
	background-color: #ffffff;
}
.cbp-popup-singlePage .cbp-popup-prev:hover {
	background-position: 0 -182px;
	transition: 0.5s;
	background-color: #ffffff;
}

.cbp-popup-singlePage-open .cbp-nav-controls {
	display: none;
}
.cbp-popup-singlePage-open .cbp-slider .cbp-nav-pagination {
	width: 100%;
	text-align: center;
	right: 0;
	left: 0;
}
.cbp-popup-singlePage-open .cbp-slider .cbp-nav-pagination-item {
	background: #ffffff;
	margin: 0 6px;
}
.cbp-popup-singlePage-open .cbp-slider .cbp-nav-pagination-active {
	background: #1f1f1f;
	transform: scale(1.5);
}

/* ===================================
    price Starts
====================================== */

.price-item {
	position: relative;
	padding: 2.5rem 0;
	max-width: 365px;
	margin: 0 auto;
	z-index: 1;
	transition: 1s;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
	background: #ffffff;
	border-radius: 10px;
}
.price-transform {
	transform: translateY(34px);
}
.price-box {
	margin-bottom: 75px;
}
.price-box h3 {
	font-size: 36px;
	color: #1f1f1f;
	text-transform: capitalize;
	font-weight: normal;
}
.price-box i {
	font-size: 55px;
	color: #eaeaea;
}
.dollar {
	position: absolute;
	font-size: 30px;
	transform: translateX(-95%);
	top: 5px;
	font-weight: 300;
}
.price {
	padding-bottom: 40px;
}
.price h2 {
	font-size: 60px;
	font-weight: 100;
}
.price span.month {
	font-family: "Raleway", sans-serif;
	font-size: 15px;
	font-weight: 400;
	color: #1f1f1f;
}
.price-sub-heading {
	margin-bottom: 20px;
	font-size: 17px;
	color: #1f1f1f;
	font-weight: 100;
}
.price-padding {
	padding: 7.5rem 0 4rem;
}

.price-features {
	padding: 30px 0;
	color: #ffffff;
}
.price-description {
	padding-bottom: 65px;
}
.price-description p {
	color: #686868;
	padding: 15px 0;
}
.price-description p:last-child {
	margin-bottom: 0;
}
.price-transform.basic-plan .price-description {
	padding-bottom: 120px;
}
.price-item.standard-plan {
	padding: 4rem 0;
	z-index: 2;
	max-width: 380px;
}
.price-item.standard-plan .price-box {
	margin-bottom: 40px;
}
.price-item.standard-plan .price-box i {
	font-size: 70px;
}
.price-item.standard-plan .price-description {
	padding-bottom: 40px;
}
/*
.price-item .btn-blue {
    border-color: #0284d0;
    background-color: #0284d0;
}
*/
.price-package,
.price-icon {
	transform: translateY(0);
	transition: 1s;
	animation: 1s linear 1 alternate both;
}
.price-item:hover .price-package {
	transform: translateY(210%);
	transition: 1s;
	animation: 1s linear 1 alternate both;
}
.price-item:hover .price-icon {
	transform: translateY(-130%);
	transition: 1s;
	animation: 1s linear 1 alternate both;
}
.price-item.standard-plan:hover .price-package {
	transform: translateY(-280%);
	transition: 1s;
	animation: 1s linear 1 alternate both;
}
.price-item.standard-plan:hover .price-icon {
	transform: translateY(100%);
	transition: 1s;
	animation: 1s linear 1 alternate both;
}

/* ===================================
    Clients Starts
====================================== */
.slick-dots {
	transform: translateY(-300%);
}
.slick-dots li.slick-active button:before {
	opacity: 0.75;
	color: #ffffff;
}
.slick-dots li button:before {
	font-size: 12px;
	color: #ffffff;
}
.testimonial-text {
	max-width: 650px;
}
.quote {
	font-size: 20px;
	color: #ffffff;
}

.testimonial-one .testimonial-icon {
	display: block;
	font-size: 28px;
	color: #acacac;
	margin-bottom: 15px;
}
.testimonial-one .testimonial-item .rating {
	margin-top: 5px;
}
.testimonial-one .testimonial-item .rating i {
	font-size: 15px;
	color: #f9ce4c;
}

.testimonial-person {
	background: #0277bb;
	border-radius: 5px;
	width: 100%;
	max-width: 376px;
	height: 510px;
}

.testimonial-person .post {
	height: 120px;
	width: 120px;
}
.testimonial-person .post > img {
	width: 100%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

.testimonial-social-icons i {
	font-size: 20px;
	color: #ffffff;
}

/* owl carousel brands */
.brand-carousel.owl-carousel .owl-item img {
	margin: 0 auto;
	cursor: pointer;
	width: 200px;
}

/* our partners */
.our-partners-content img {
	display: block;
	margin: 0 auto;
	cursor: pointer;
	padding: 30px 20px;
	width: 200px;
	max-width: 100%;
}
/* ===================================
   Request
====================================== */
.split-box-container-setting {
	max-width: 650px;
	padding: 3rem 0;
}
.select2-container--default .select2-selection--single {
	letter-spacing: 0.7px;
	font-weight: 300;
	-webkit-border-radius: 0;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	height: 44px;
	text-align: left;
	font-size: 15px;
	border: none;
	color: #1f1f1f;
	-webkit-transition: 300ms ease-in all;
	background: #f5f6f6;
	-o-transition: 300ms ease-in all;
	transition: 300ms ease-in all;
	padding: 0 15px;
}
.select2-container--default
	.select2-selection--single
	.select2-selection__rendered {
	font-size: 15px;
	color: #1f1f1f;
	font-weight: 400;
	padding-left: 0;
	padding-right: 0;
	line-height: 44px;
}
.select2-container--default
	.select2-selection--single
	.select2-selection__arrow {
	top: 12px;
}
.select2-dropdown {
	z-index: 20;
}
.select2-results__option--highlighted[aria-selected] {
	background-color: #28a745 !important;
}
.select2 {
	width: 100% !important;
}

.select2-container--default
	.select2-selection--single
	.select2-selection__arrow
	b {
	border-color: #1f1f1f transparent transparent transparent;
	left: 0;
}
.select2-container--default.select2-container--open
	.select2-selection--single
	.select2-selection__arrow
	b {
	border-color: transparent transparent #1f1f1f transparent;
}
.select2-container,
.select2-dropdown,
.select2-search,
.select2-results {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-ms-transition: none !important;
	-o-transition: none !important;
	transition: none !important;
}
.request-form-textfield input,
.request-form-textfield textarea {
	font-size: 15px;
	border: none;
	color: #1f1f1f !important;
	background: #f5f6f6;
}

.request-form-textfield input::-webkit-input-placeholder,
.request-form-textfield textarea::-webkit-input-placeholder,
.request-form-textfield input:-moz-placeholder,
.request-form-textfield textarea:-moz-placeholder,
.request-form-textfield input::-moz-placeholder,
.request-form-textfield textarea::-moz-placeholder,
.request-form-textfield input:-ms-input-placeholder,
.request-form-textfield textarea:-ms-input-placeholder,
.request-form-textfield input::placeholder,
.request-form-textfield textarea::placeholder {
	color: #ffffff !important;
}
.request-form-textfield .form-control::placeholder {
	color: #1f1f1f;
	opacity: 1;
}
.request-form-textfield input:focus,
.request-form-textfield textarea:focus {
	border-color: #dcdbdb;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #333333;
}
.request-form-textfield textarea {
	height: 120px;
	resize: none;
}
/* features 4 boxes */
.process-box .pro-step {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	border-radius: 50%;
}
.process-box {
	background: #fff;
	-webkit-transition: all 0.5s ease-in;
	-o-transition: all 0.5s ease-in;
	transition: all 0.5s ease-in;
	-webkit-border-radius: 0;
	border-radius: 0;
}

.process-box .pro-step {
	height: 70px;
	width: 70px;
	line-height: 70px;
	font-size: 24px;
	color: #ffffff;
	text-align: center;
	border: 1px solid;
	border-color: transparent;
	border-radius: 50%;
	background-color: #f9f9f9;
	position: relative;
}
.process-box:hover .pro-step {
	border-color: #f9f9f9;
	background-color: rgba(71, 69, 228, 0.1);
}

.process-box .pro-step:before {
	content: "";
	position: absolute;
	top: 5px;
	right: 5px;
	left: 5px;
	bottom: 5px;
	background: #28a745;
	border-radius: 50%;
	z-index: 0;
	opacity: 1;
	transform: scale(1);
	transition: all 0.3s ease-in-out;
}

.process-box:hover .pro-step:before {
	transform: scale(0);
}
.process-box .pro-step i {
	z-index: 1;
	position: relative;
}
.process-box:hover .pro-step i {
	color: #28a745;
}

/* ===================================
   Contact
====================================== */

.brand-transform-padding {
	padding: 0 0 250px;
}
.footer-transform-padding {
	padding: 60px 0 0;
}
.contact-transform {
	transform: translateY(-52%);
	overflow: visible;
}

.box-shadow-contact {
	box-shadow: -10px 0 20px 0 rgba(0, 0, 0, 0.02);
}
.box-shadow-map {
	box-shadow: 10px 0 20px 0 rgba(0, 0, 0, 0.02);
}
.contact-box {
	background: #ffffff;
	padding: 70px 50px;
}
.alert-danger {
	border-radius: 36px;
}
.contact-para-setting {
	max-width: 480px;
}
.contact-info .icon-box {
	display: table;
	padding-top: 1.5rem;
}
.contact-info .icon-box:last-child {
	padding-bottom: 0.5rem;
}
.contact-info h5 {
	margin-bottom: 2rem;
}
.contact-info i {
	display: table-cell;
	font-size: 30px;
	padding-right: 15px;
}
.contact-info p {
	transform: translateY(-10px);
}
.form-control {
	padding: 1.3rem 0.75rem !important;
}
.form-control.message {
	padding: 13px 10px 10px;
	height: 150px;
}
.contact-form-textfield input,
.contact-form-textfield textarea {
	font-size: 14px;
	border: 1px solid #dcdbdb;
	color: #535353 !important;
	/*    border-radius: 30px;*/
	-webkit-transition: 300ms ease-in all;
	-o-transition: 300ms ease-in all;
	transition: 300ms ease-in all;
}
.contact-form-textfield input::-webkit-input-placeholder,
.contact-form-textfield textarea::-webkit-input-placeholder,
.contact-form-textfield input:-moz-placeholder,
.contact-form-textfield textarea:-moz-placeholder,
.contact-form-textfield input::-moz-placeholder,
.contact-form-textfield textarea::-moz-placeholder,
.contact-form-textfield input:-ms-input-placeholder,
.contact-form-textfield textarea:-ms-input-placeholder,
.contact-form-textfield input::placeholder,
.contact-form-textfield textarea::placeholder {
	color: #333333 !important;
}
.contact-form-textfield input:focus,
.contact-form-textfield textarea:focus {
	border-color: #dcdbdb;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #333333;
}
.contact-form-textfield textarea {
	height: 120px;
	resize: none;
}
.form-control {
	box-shadow: none;
}

.contact-box-shadow {
	background: #ffffff;
	-webkit-box-shadow: 0px 0px 26px 16px rgba(0, 0, 0, 0.02);
	box-shadow: 0px 0px 26px 16px rgba(0, 0, 0, 0.02);
	padding: 30px 25px;
}
.contact-box-shadow h2 {
	text-transform: capitalize;
	margin-bottom: 1.15rem !important;
}

.contact-box-shadow .contact-para-setting {
	margin-bottom: 1.5rem !important;
}

.contact-info .icon-box:first-child {
	padding-top: 0;
}
.contact-form-center {
	max-width: 950px;
	margin: 0 auto;
}

/* contact split */
.address-social li {
	display: inline-block;
}

.address-social a {
	display: block;
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	border-radius: 50px;
	color: #fff;
	font-size: 15px;
	margin: 5px;
	border: 1px solid transparent;
	-webkit-transition: background-color 0.2s ease-in-out;
	-moz-transition: background-color 0.2s ease-in-out;
	-ms-transition: background-color 0.2s ease-in-out;
	-o-transition: background-color 0.2s ease-in-out;
	transition: background-color 0.2s ease-in-out;
}

.address-social a:hover {
	background-color: #ffffff;
}
.address-social.black a {
	color: #1f1f1f;
}
/* ===================================
    Footer
====================================== */

footer.bg-black2 {
	background: #f8f9fa;
}
.footer-icons li {
	display: inline-block;
}
.footer-icons li a {
	display: block;
	height: 55px;
	width: 55px;
	font-size: 18px;
	color: #676767;
	line-height: 60px;
	text-align: center;
	margin: 0 0.1rem;
	border-radius: 40px;
	background: transparent;
	-webkit-transition: all 0.3s ease !important;
	-o-transition: all 0.3s ease !important;
	transition: all 0.3s ease !important;
}
.copyrights,
.copyrights a {
	font-size: 14px;
	color: #535353;
}
.copyrights a:hover,
.copyrights a:focus {
	color: #d91a60;
}
.footer-icons li a:hover,
.footer-icons li a:focus {
	color: #ffffff;
}
.footer-icons li a.facebook:hover,
.footer-icons li a.facebook:focus {
	-webkit-box-shadow: 0 0 15px 30px #4267b2 inset;
	box-shadow: 0 0 15px 30px #4267b2 inset;
}
.footer-icons li a.twitter:hover,
.footer-icons li a.twitter:focus {
	-webkit-box-shadow: 0 0 15px 30px #1da1f2 inset;
	box-shadow: 0 0 15px 30px #1da1f2 inset;
}
.footer-icons li a.google:hover,
.footer-icons li a.google:focus {
	-webkit-box-shadow: 0 0 15px 30px #db4437 inset;
	box-shadow: 0 0 15px 30px #db4437 inset;
}
.footer-icons li a.linkedin:hover,
.footer-icons li a.linkedin:focus {
	-webkit-box-shadow: 0 0 15px 30px #0077b5 inset;
	box-shadow: 0 0 15px 30px #0077b5 inset;
}
.footer-icons li a.instagram:hover,
.footer-icons li a.instagram:focus {
	-webkit-box-shadow: 0 0 15px 30px #c32aa3 inset;
	box-shadow: 0 0 15px 30px #c32aa3 inset;
}
.footer-icons li a.pinterest:hover,
.footer-icons li a.pinterest:focus {
	-webkit-box-shadow: 0 0 15px 30px #bd081c inset;
	box-shadow: 0 0 15px 30px #bd081c inset;
}
.footer-icons li a i:hover {
	color: #ffffff;
	border-radius: 40px;
}

.fullscreen .center-col,
.not-fullscreen .center-col {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
}

/* background setting */
.bg-img-setting {
	background-size: cover;
	background-position: center center;
	width: 100%;
}
/* parallax images */

/* parallax setting */
.parallax-setting {
	-webkit-background-size: cover;
	background-size: cover;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-position: center center;
	overflow: hidden;
	width: 100%;
}

/* page header */

.page_header {
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
}
.page_header .page-content h2 {
	font-size: 2rem;
}
.page_header .page-content .page_nav {
	-webkit-border-top-left-radius: 4px;
	border-top-left-radius: 4px;
	-webkit-border-top-right-radius: 4px;
	border-top-right-radius: 4px;
	padding: 18px 30px 18px;
	margin-top: 80px;
	color: #7f7f7f;
	font-size: 0.875rem;
	float: right;
	margin-bottom: -2px;
}

.page_header .page-content {
	margin: 5rem 0 8rem 0;
}

/* ===================================
    Blog, Blog Pages & News Starts
====================================== */

/* Blog + Blog Pages */

.news_item {
	background: #ffffff;
	position: relative;
}
.news_item.blog-one:hover img,
.news_item.blog-two:hover img {
	transform: scale(1.2);
}
.news_item.shadow {
	-webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}
.news_item .news_desc {
	padding: 40px 120px;
}
/* blog post */
.blog-post {
	overflow: hidden;
}

.blog-post .post {
	height: 45px;
	width: 45px;
	border-radius: 4px;
}
.blog-post .post img {
	width: 100%;
	border-radius: 50%;
}
.blog-post .blog-tags {
	vertical-align: middle;
	font-size: 15px;
}
.blog-post span {
	font-size: 13px;
	display: block;
}

.news_item:hover h3 a {
	color: #0284d0;
}

/* widgets */
.widget {
	background: #ffffff;
	padding: 2rem 1.5rem;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	-webkit-box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.widget button,
.widget-parallax button {
	border: none;
}
.blog-box {
	-webkit-box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.widget-parallax {
	padding: 2rem 1.5rem;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	-webkit-box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.1);
}
/* form search */
.search-box .form-control {
	padding-top: 1.3rem;
	padding-bottom: 1.3rem;
	/* -webkit-border-bottom-left-radius: 20px;
    border-bottom-left-radius: 20px;
    -webkit-border-top-left-radius: 20px;
    border-top-left-radius: 20px;*/
}
.search-box .form-control,
.search-box .search-addon {
	background: #f6f6f6;
	border-color: #f6f6f6;
	-webkit-box-shadow: none;
	box-shadow: none;
	display: table-cell;
}
.search-box .form-control:focus,
.search-box textarea:focus {
	border-color: #0284d0;
	-webkit-box-shadow: 0 0;
	box-shadow: 0 0;
}
.search-box .search-addon {
	background-color: #0284d0;
	color: #ffffff;
	position: relative;
	top: 0;
	left: 0;
	width: 50px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	/* -webkit-border-top-right-radius: 20px;
    border-top-right-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    border-bottom-right-radius: 20px;*/
}
.search-addon:hover {
	background: #28a745;
}
.search-box.bg-change .search-addon {
	background-color: #28a745;
}
.search-box.bg-change .search-addon:hover {
	background-color: #0284d0;
}
.search-box.bg-change .form-control:focus,
.search-box.bg-change textarea:focus {
	border-color: #ffffff;
}
/* recent post */
.single-post {
	overflow: hidden;
	display: table;
}
.single-post .post,
.single-post .text {
	display: table-cell;
}
.single-post .post {
	height: 60px;
	width: 60px;
}
.single-post .post > img {
	width: 100%;
	height: 100%;
	/*  -webkit-border-radius: 50%;
    border-radius: 50%;*/
}
.single-post .text {
	vertical-align: middle;
	padding-left: 10px;
	font-size: 15px;
}
.single-post span {
	font-size: 13px;
	display: block;
}
.single-post:hover a,
.single-post:focus a {
	color: #d91a60;
}
/* categories */
.categories-name > a {
	display: block;
	padding: 18px 0 5px;
	border-bottom: 1px solid #d8d8d8;
}
.categories-name > a > p:hover {
	color: #d91a60;
}

.categories-name > a > p > span {
	float: right;
	width: 40px;
	text-align: center;
	border-radius: 4px;
	font-size: 15px;
}
.categories-name > a > p:hover span {
	background: #0284d0;
	color: #ffffff;
}
/* tags */
.tags li {
	display: inline-block;
	padding: 2px 12px 2px;
	border: 1px solid #838383;
	border-radius: 4px;
	cursor: pointer;
	-webkit-transition: 300ms all linear;
	-o-transition: 300ms all linear;
	transition: 300ms all linear;
	margin: 0 1px 6px 0;
}
.tags li p {
	color: #838383;
}
.tags li:hover,
.tags li:focus {
	background: #0284d0;
	border: 1px solid #0284d0;
	-webkit-transition: 300ms all linear;
	-o-transition: 300ms all linear;
	transition: 300ms all linear;
}
.tags li:hover p,
.tags li:focus p {
	color: #ffffff;
}
ul.rounded li {
	display: flex;
	margin-top: 20px;
}
ul.rounded li::before {
	content: "";
	height: 10px;
	width: 10px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background: #0284d0;
	margin-top: 6px;
	margin-right: 10px;
	vertical-align: middle;
	display: inline-block;
}
/* post comments + tabs */
.any-profile {
	border-bottom: 1px solid #e4e4e4;
	padding-bottom: 20px;
	display: flex;
	align-items: center;
}
.any-profile .profile-photo {
	display: table-cell;
	vertical-align: top;
}
.any-profile .profile-text {
	display: table-cell;
	vertical-align: middle;
}
.any-profile .profile-text h6 {
	line-height: 1 !important;
	font-size: 0.9rem;
}
.any-profile .profile-text p {
	font-size: 0.8rem;
	color: #838383;
	line-height: 1.1;
}
.any-profile .profile-photo {
	height: 45px;
	width: 45px;
}
.any-profile .profile-photo > img {
	-webkit-border-radius: 50%;
	border-radius: 50%;
	width: 100%;
}
/* pagination */
.pagination li > a {
	height: 38px;
	width: 38px;
	padding: 0;
	line-height: 38px;
	font-size: 18px;
	color: #a5a5a5;
	text-align: center;
	margin: 0 4px;
	border: none;
}
.pagination li > a i {
	font-size: 14px;
}
.pagination li > a,
.pagination li:first-child a,
.pagination li:last-child a {
	-webkit-border-radius: 50% !important;
	border-radius: 50% !important;
}
.pagination li > a:hover,
.pagination li > a:focus {
	background: #0284d0;
}
.pagination li > a:hover,
.pagination li > a:focus,
.pagination li.active > a {
	color: #ffffff;
}
.pagination li.active > a {
	background: #0284d0 !important;
}
.page-link:focus {
	box-shadow: none;
}
.blockquote {
	font-size: 17px;
}

/* newsletter */

.newsletter {
	text-align: center;
	background: #ffffff;
	border-radius: 10px;
	padding: 30px;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
	max-width: 505px;
}

.newsletter-icon i {
	font-size: 45px;
	color: #1f1f1f;
}

.subscribe-box .form-control {
	background: #f6f6f6;
	border-color: #f6f6f6;
	-webkit-box-shadow: none;
	box-shadow: none;
	display: table-cell;
	font-size: 14px;
	padding-top: 1.3rem;
	padding-bottom: 1.3rem;
	border-radius: 0;
	max-width: 250px;
	margin-bottom: 0;
}

.subscribe-box .form-control:focus,
.subscribe-box textarea:focus {
	border-color: #0284d0;
	-webkit-box-shadow: 0 0;
	box-shadow: 0 0;
}

/* ===================================
   Social Icons Popup
====================================== */
.social-icons-popup {
	position: absolute;
	width: 10px;
	height: 10px;
	right: 30px;
	transform: translateY(1000%);
	margin: 0 auto;
	cursor: pointer;
	z-index: 5;
}
.social-icons-popup a {
	position: absolute;
	z-index: -1;
}
.social-icons-popup-inner {
	position: relative;
	z-index: 2;
}
.social-icons-popup-outer {
	height: 10px;
	background: #0284d0;
	z-index: 8;
	width: 10px;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	display: flex;
	position: absolute;
	transform: translateX(-200%);
}

.share-icon {
	font-size: 25px;
	color: #ffffff;
}
.circle-bg-fb,
.circle-bg-tw,
.circle-bg-i,
.circle-bg-p {
	width: 50px;
	height: 50px;
	color: #ffffff;
	border-radius: 50%;
	text-align: center;
	box-sizing: border-box;
	padding: 10px;
	transform: translateX(-80%);
}
.circle-bg-fb {
	position: absolute;
	background-color: #3b5998;
	top: 0;
	left: 0;
	-webkit-transition: all 1s;
	transition: all 1s;
	justify-content: center;
	display: flex;
	align-items: center;
	visibility: hidden;
}
.circle-bg-fb-end {
	top: 80px;
	left: 0;
	-webkit-transition: all 1s;
	transition: all 1s;
	visibility: visible;
}
.circle-bg-tw {
	position: absolute;
	background-color: #55acee;
	top: 0;
	left: 0;
	-webkit-transition: all 1s;
	transition: all 1s;
	justify-content: center;
	display: flex;
	align-items: center;
	visibility: hidden;
}
.circle-bg-tw-end {
	top: 160px;
	left: 0;
	-webkit-transition: all 1s;
	transition: all 1s;
	visibility: visible;
}
.circle-bg-i {
	position: absolute;
	background-color: #0077b5;
	top: 0;
	left: 0;
	-webkit-transition: all 1s;
	transition: all 1s;
	justify-content: center;
	display: flex;
	align-items: center;
	visibility: hidden;
}
.circle-bg-i-end {
	top: 240px;
	left: 0;
	-webkit-transition: all 1s;
	transition: all 1s;
	visibility: visible;
}
.circle-bg-p {
	position: absolute;
	background-color: #bd081c;
	top: 0;
	left: 0;
	-webkit-transition: all 1s;
	transition: all 1s;
	justify-content: center;
	display: flex;
	align-items: center;
	visibility: hidden;
}
.circle-bg-p-end {
	top: 320px;
	left: 0;
	-webkit-transition: all 1s;
	transition: all 1s;
	visibility: visible;
}

/* ===================================
   Loader
====================================== */
.loader {
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 111111;
	background: #ffffff;
	overflow: hidden !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader-box {
	position: relative;
	display: grid;
	grid-template-columns: 33% 33% 33%;
	grid-gap: 2px;
	height: 90px;
	margin: 0 50px 100px 0;
	width: 90px;
}

.loader-box li {
	position: relative;
	width: 100%;
	height: 100%;
	background: #28a745;
	transform: scale(0);
	transform-origin: center center;
	animation: loader-box-fade 2s infinite linear;
}
.loader-box li:nth-child(1),
.loader-box li:nth-child(5),
.loader-box li:nth-child(9) {
	animation-delay: 0.4s;
}
.loader-box li:nth-child(4),
.loader-box li:nth-child(8) {
	animation-delay: 0.2s;
}
.loader-box li:nth-child(2),
.loader-box li:nth-child(6) {
	animation-delay: 0.6s;
}
.loader-box li:nth-child(3) {
	animation-delay: 0.8s;
}
@keyframes loader-box-fade {
	0% {
		transform: scale(0);
	}
	40% {
		transform: scale(1);
	}
	80% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
/* ===================================
   Media Queries
====================================== */

@media (max-width: 1280px) and (min-width: 992px) {
	.about-img {
		height: 580px;
	}
	.image img.about-img {
		height: 580px;
	}
}

@media screen and (max-width: 1199px) {
	h1,
	.h1 {
		font-size: 3.56rem;
	}
	h2 {
		font-size: 2.45rem;
	}
	h3 {
		font-size: 1.65rem;
	}
	h4 {
		font-size: 1.15rem;
	}
	h5,
	.cbp-l-project-subtitle {
		font-size: 1rem;
	}
	.navbar-top-default .navbar-nav .nav-link {
		padding-right: 0.1rem;
		padding-left: 0.1rem;
		margin-right: 1rem;
		margin-left: 1rem;
	}
	.split-container-setting {
		max-width: 400px;
		margin-right: 67px;
	}
	.split-container-setting.style-two {
		max-width: 400px;
		margin-left: 70px;
	}
	.split-container-setting.style-three {
		max-width: 400px;
	}
}
@media screen and (max-width: 991px) {
	h1,
	.h1 {
		font-size: 2.95rem;
	}
	h2 {
		font-size: 2.3rem;
	}
	h3 {
		font-size: 1.4rem;
	}
	h4 {
		font-size: 1.125rem;
	}
	.mt-sm-40px {
		margin-top: 40px;
	}
	.sm-mt-2rem {
		margin-top: 2rem;
	}
	.sm-mt-5 {
		margin-top: 3rem;
	}
	.sm-mt-15px {
		margin-top: 15px;
	}
	.sm-mb-10px {
		margin-bottom: 10px;
	}
	.md-mb-5 {
		margin-bottom: 3rem;
	}
	.sm-text-center {
		text-align: center !important;
	}
	.sm-p-0 {
		padding: 0;
	}
	#home.h-100vh {
		min-height: unset !important;
	}

	.about-img,
	.image img.about-img {
		height: auto;
		width: 100%;
	}
	.split-container-setting,
	.split-container-setting.style-two,
	.split-container-setting.style-three {
		padding: 7.5rem 0;
		max-width: 720px;
		margin: 0 auto;
	}

	.testimonial-post {
		margin: 0 auto;
	}
	.testimonial-post .post,
	.testimonial-post .text-content {
		display: block;
		margin: 0 auto;
	}

	.side-menu {
		width: 45%;
	}

	.split-box-container-setting {
		max-width: 650px;
		padding: 0 15px;
		margin: 0 auto;
	}
	.contact-transform {
		transform: translateY(-50%);
	}
	.contact-box {
		padding: 65px 45px;
	}
	.contact-transform .col-map #google-map {
		height: 494px;
	}
}
@media screen and (max-width: 767px) {
	body {
		text-align: center;
	}

	h1,
	.h1 {
		font-size: 2.65rem;
	}

	h2,
	.main-title.style-two h2,
	.cbp-l-project-title {
		font-size: 2.2rem !important;
	}

	h3 {
		font-size: 1.375rem !important;
	}

	h4 {
		font-size: 1.115rem !important;
	}
	p {
		font-size: 14px !important;
	}
	.logo {
		max-width: 120px;
	}
	.side-menu {
		width: 380px;
	}

	section,
	.section-padding,
	.price-padding {
		padding: 4.5rem 0;
	}
	.awaza {
		display: none !important;
	}
	.d-xs-none {
		display: none !important;
	}
	.d-xs-block {
		display: inline-block !important;
	}
	.mt-xs-4rem {
		margin-top: 4rem !important;
	}
	.mt-xs-40px {
		margin-top: 40px;
	}
	.mb-xs-5 {
		margin-bottom: 3rem !important;
	}
	.mb-xs-2rem {
		margin-bottom: 2rem !important;
	}
	.mb-xs-3 {
		margin-bottom: 1rem !important;
	}
	.mt-xs-0 {
		margin-top: 0 !important;
	}
	.mb-xs-0 {
		margin-bottom: 0 !important;
	}
	.mr-xs-0 {
		margin-right: 0 !important;
	}
	.ml-xs-0 {
		margin-left: 0 !important;
	}
	.mrl-xs-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.m-xs-0 {
		margin: 0 !important;
	}
	.pt-xs-4 {
		padding-top: 1.5rem !important;
	}
	.pt-xs-25px {
		padding-top: 25px;
	}
	.pt-xs-0 {
		padding: 0 !important;
	}
	.pb-xs-0 {
		padding-bottom: 0 !important;
	}
	.pr-xs-0 {
		padding-right: 0 !important;
	}
	.pl-xs-0 {
		padding-left: 0 !important;
	}
	.price-item {
		padding: 2rem 2rem 3rem;
	}
	.text-xs-center {
		text-align: center !important;
	}
	.btn-setting {
		font-size: 13px;
		padding: 8px 28px;
	}

	.w-xs-50 {
		width: 50%;
	}

	.split-container-setting,
	.split-container-setting.style-two,
	.split-container-setting.style-three {
		max-width: 540px;
		padding: 4.5rem 0;
	}

	.main-title {
		margin-bottom: 2.5rem;
	}
	.main-title h2,
	.cbp-l-project-title {
		margin-bottom: 1.2rem;
	}

	.order-xs-2 {
		order: 2;
	}

	.footer-icons li a {
		height: 45px;
		width: 45px;
		font-size: 16px;
		line-height: 50px;
	}

	.price-transform {
		transform: translateY(0);
	}

	.contact-transform {
		transform: translateY(-50%);
	}
	.contact-box {
		padding: 4.5rem 0;
	}
	.brand-transform-padding {
		padding: 0 0 490px;
	}
	.footer-transform-padding {
		padding: 190px 0 0;
	}
}
@media screen and (max-width: 575px) {
	.side-menu .inner-wrapper {
		padding: 2.5rem 5rem 3.5rem;
	}
	.cbp-item-wrapper h3.sm-mb-10px {
		margin-bottom: 0;
	}
	.mt-xxs-40px {
		margin-top: 40px;
	}
	.loading {
		top: 46%;
		left: 46%;
	}
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		line-height: 1.4;
	}
	.main-title h5 {
		line-height: 1;
		margin-bottom: 1rem;
	}
	.loader-box {
		position: relative;
		display: grid;
		grid-template-columns: 33% 33% 33%;
		grid-gap: 2px;
		height: 70px;
		margin: 0 auto 45px auto;
		width: 70px;
	}
}
@media screen and (max-width: 480px) {
	.slide-action span {
		height: 45px;
		width: 45px;
		font-size: 11px;
	}
	.slide-action span i {
		line-height: 45px;
	}

	.mb-xxs-3 {
		margin-bottom: 1rem !important;
	}

	.side-menu {
		width: 370px;
	}
}
.vertical-timeline-element-icon {
	display: grid;
	place-items: center;
}
