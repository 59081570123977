.agile-home {
	background-image: url("../../Images/agile2.jpg");
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	color: #ffffff;
}
.appDevMain-home {
	background-image: url("../../Images/appMainDev (1).jpg");
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	color: #ffffff;
}
.bigData-home {
	background-image: url("../../Images/bigData1.jpg");
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	color: #ffffff;
}
.lowCode-home {
	background-image: url("../../Images/low-code 1.jpg");
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	color: #ffffff;
}
.prodEng-home {
	background-image: url("../../Images/Product-Engineering.png");
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	color: #ffffff;
}
.agile-home .main-font {
	font-family: "Raleway", sans-serif;
}
.agile-home h2,
.agile-home h5 {
	line-height: 1.2;
	font-weight: 400;
}
.agile-home {
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	color: #ffffff;
}
.agile-home video {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	object-fit: cover;
}
.agile-home h2 {
	font-size: 74px;
}
